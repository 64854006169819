<style scoped>
    .chips-img-shadow>img{
        box-shadow: 0px 3px 2px 0px #000;
    }
    .swiper{
        --swiper-navigation-color: #fff;/* 单独设置按钮颜色 */
        --swiper-navigation-size: 1.8rem;/* 设置按钮大小 */
    }
    .text-red{
        color:#c90013;
    }
    .amount.active{
        animation: amount .5s ease infinite alternate;
    }
    .hover-bg-opacity-50:hover{
        --bs-bg-opacity: 0.5;
    }
    #fullPearl{
        transition: left .5s ease;
    }
    .big-scale-small{
        animation: big-scale-small .2s ease-in .2s;
    }
    @keyframes big-scale-small{
        0%{
            font-size: 4rem;
        }
        100%{
            font-size: 1.5rem;
        }
    }
    @keyframes amount {
        0% {
            box-shadow: rgb(249,252,0) 0 0 .2rem .2rem;
        }
        100% {
            box-shadow: rgba(249,252,255) 0 0 .2rem .2rem;
        }
    }
    .coruscate{
        animation: coruscate .5s ease infinite alternate;
    }
	@keyframes coruscate {
        0% {
            background: rgba(25,135,84,1);
        }
        100% {
            background: rgba(13,202,240);
        }
    }
    .scale-2{
        animation:scale2 .12s ease-out;
    }
    @keyframes scale2 {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(1.5);
        }
    }
    .untransition{
        transition: none !important;
    }
    @media (min-width:576px) {
        .tel-video-h{
            /* 26.917rem */
            height: 23.211rem;
        }
    }
    @media (min-width:768px) {
        .tel-video-h{
            height: 25.523rem;
        }
    }
    @media (min-width: 992px){
        .tel-video-h{
            height: 27.072rem;
        }
    }
    @media (min-width: 1200px){
        .tel-video-h{
            height: 27.194rem;
        }
    }
    @media (min-width: 1400px){
        .tel-video-h{
            height: 28.656rem;
        }
    }
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar :class="[isMultiple?'':'d-sm-none']" class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <div class="d-flex flex-grow-1 h-0 flex-column" :class="[(gameModel==GAME_MODEL.normal)?(isMultiple?'flex-sm-row':'position-xs-relative start-0 end-0 top-0'):(isMultiple?'position-relative flex-sm-row':'position-relative')]">
            <!-- 多台列表 -->
            <div v-if="isMultiple" class="col-sm-7 col-lg-7 order-2 order-sm-1 flex-grow-1 flex-sm-grow-1 h-0 h-sm-100 pe-sm-2 py-2 py-sm-0">
                <room-list class="col-12 col-xl-6" :class="{'col-md-6':isMultiple}" :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" @get-room-pearl="getRoomPearl" @on-ready="isModuleReady=true"></room-list>
            </div>
            <!-- 经典 -->
            <div :class="{'col-sm-5 col-md-4 col-xxl-3 order-1 h-sm-100':isMultiple,'flex-grow-0 flex-sm-grow-1 h-sm-0':gameModel==GAME_MODEL.normal}" class="d-flex flex-column flex-sm-row position-relative">
                <!-- 纵向导航 -->
                <navbar-vertical v-if="!isMultiple" class="d-none d-sm-flex" :is-fullscreen="isFullscreen"></navbar-vertical>
                <!-- 电话模式视频 -->
                <div class="d-flex flex-column flex-sm-row position-relative" v-if="gameModel==GAME_MODEL.tel">
                    <div class="col">
                        <div class="pt-60 h-0 position-relative">
                            <media-video class="border-start border-end border-secondary position-absolute top-0 start-0" :video-index="0" id="firstvideo" :video-list="videoList" :countdown="roomInfo.countdown" :room-class="(roomInfo.isar==1&&gameModel==GAME_MODEL.normal&&!isMultiple)?['top-xs-0 top-sm-5rem mt-2 mt-sm-1 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(roomInfo.isar==1&&gameModel==GAME_MODEL.normal&&!isMultiple)?['top-xs-0','top-sm-5rem top-xl-6rem','ms-2']:['top-0','m-2']" :img-class="(!isMultiple)?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                        </div>
                    </div>
                    <div class="col">
                        <div class="pt-60 h-0 position-relative">
                            <media-video class="border-start border-end border-secondary position-absolute top-0 start-0" :video-index="1" id="secondvideo" :video-list="videoList" :room-class="['d-none']" :time-class="['d-none']" :img-class="(!isMultiple)?['h-100','w-100']:['position-absolute translate-middle top-50 start-50']"></media-video>
                        </div>
                    </div>
                </div>
                <!-- 视频区 -->
                <div v-else class="d-flex flex-column flex-grow-1 position-relative">
                    <!-- 公告 -->
                    <notice></notice>
                    <!-- 视频 -->
                    <div class="d-flex h-0 pt-60 position-relative index-1" :class="{'':isMultiple,'flex-grow-1 pt-sm-0':!isMultiple}">
                        <media-video id="video1" class="position-xs-absolute top-0 start-0" :class="{'position-absolute':isMultiple,'position-sm-relative top-0 start-0':!isMultiple,'scale-video':isScale,'bg-transparent img-sm-thumbnail':roomInfo.isar!=1&&!isMultiple}" :video-index="0" :video-list="videoList" :countdown="roomInfo.countdown" :room-class="(!isMultiple)?['top-xs-0 top-sm-0 mt-2 mt-sm-5 ms-2']:['top-xs-0 top-sm-0 m-2']" :time-class="(!isMultiple)?['top-0 mt-2 mt-sm-5 ms-2']:['top-0 m-2']" :img-class="['position-absolute translate-middle top-50 start-50 w-100 h-sm-100']" @video-ready="videoReady"></media-video>
                        <media-video v-if="roomInfo.isar!=1&&!isMultiple" class="d-none d-sm-block" id="video2" :video-index="1" :class="{'scale-video':isScale,'bg-transparent img-sm-thumbnail':roomInfo.isar!=1}" :video-list="videoList" :countdown="roomInfo.countdown" :room-class="(!isMultiple)?['d-none']:['m-2']" :time-class="(!isMultiple)?['d-none']:['top-0 m-2']" :img-class="['position-absolute translate-middle top-50 start-50 w-100 h-sm-100 bg-transparent']" @video-ready="v=>readyVideoIdArr.push(v)"></media-video>
                    </div>
                    <!-- 提交区 -->
                    <div class="position-xs-relative start-0 end-0 bottom-0 top-0 bg-xs-success" :class="{'position-sm-absolute':roomInfo.isar==1&&!isMultiple,'flex-grow-1 d-flex':isMultiple}" :style="{background:roomInfo.isar!=1&&!isMultiple?'#016751':''}">
                        <!-- 操作区 -->
                        <div v-if="gameModel==GAME_MODEL.normal" class="position-xs-relative bottom-0 index-1" :class="{'perspective-600':!isMultiple,'flex-grow-1 d-flex':isMultiple,'scale-submit':isScale==true,'translate-middle-x start-50 position-sm-absolute':roomInfo.isar==1&&!isMultiple}" :style="{background:roomInfo.isar!=1&&!isMultiple?'#016751':''}">
                            <div class="pb-1 pb-sm-0" :class="{'flex-grow-1 d-flex mb-sm-1 mb-md-2':isMultiple,'mb-sm-2':!isMultiple,'rotate3d-x-sm-50':roomInfo.isar==1&&!isMultiple,'mx-sm-2 mx-md-5 mt-sm-1 mt-md-2':roomInfo.isar!=1&&!isMultiple}">
                                <div class="d-flex flex-column" :class="{'flex-grow-1':isMultiple,'flex-sm-row':!isMultiple,'align-items-sm-end justify-content-center':roomInfo.isar!=1&&!isMultiple}">
                                    <div class="d-flex flex-column flex-grow-1" :class="{'flex-sm-row':roomInfo.isar!=1&&!isMultiple}">
                                        <!-- 切换区 -->
                                        <div class="d-flex justify-content-between mt-1 mb-1" :class="{'mt-sm-0':!isMultiple,'flex-column flex-column-reverse me-sm-2 w-sm-9rem mb-sm-0 border-end-sm':roomInfo.isar!=1&&!isMultiple}">
                                            <!-- 额度统计 -->
                                            <div class="d-flex text-light flex-grow-1 justify-content-around lh-1" :class="{'fs-sm-5':!isMultiple,'justify-content-sm-start align-items-center':roomInfo.isar==1&&!isMultiple,'flex-sm-column':roomInfo.isar!=1&&!isMultiple}">
                                                <span>{{$t("room.ingBet")}}：{{getAmountCount("submiting")}}</span>
                                                <span :class="{'ms-sm-2 ms-md-3 ms-lg-4':roomInfo.isar==1&&!isMultiple}">{{$t("room.edBet")}}：{{getAmountCount("submited")}}</span>
                                                <span :class="{'ms-sm-2 ms-md-3 ms-lg-4':roomInfo.isar==1&&!isMultiple}">{{$t("room.allBet")}}：{{submitedCount+getAmountCount("submiting")}}</span>
                                            </div>
                                            <!-- 免佣 -->
                                            <!-- <div v-if="roomInfo.isar==1&&!isMultiple" class="d-none d-sm-flex">
                                                <div class="form-check form-switch d-flex align-items-center border border-1 border-secondary rounded p-1 mb-0 me-sm-1 me-md-2">
                                                    <label class="me-1 text-light" for="cboRebateNew">{{$t("room.unRebate")}}</label>
                                                    <input class="form-check-input fs-3 m-0" type="checkbox" id="cboRebateNew" @change="setRebate($event)" :checked="currRebate==rebate.noRebate">
                                                </div>
                                            </div> -->
                                        </div>
            
                                        <div class="d-flex flex-column flex-grow-1 flex-column-reverse" :class="{'flex-sm-column-reverse':isMultiple,'flex-sm-row':roomInfo.isar==1&&!isMultiple,'flex-sm-column-reverse':roomInfo.isar!=1}">
                                            <div :class="{'flex-sm-row':roomInfo.isar!=1&&!isMultiple,'flex-sm-column-reverse':roomInfo.isar==1&&isMultiple}" class="d-flex flex-column px-1 px-sm-0">
                                                <!-- 选筹区 -->
                                                <div class="d-flex justify-content-between justify-content-sm-around align-items-center text-black order-2 order-sm-1 mb-2 mb-sm-0 flex-grow-1" :class="{'py-sm-1 py-md-2 py-lg-3':isMultiple,'flex-sm-column':roomInfo.isar==1&&!isMultiple,'col':roomInfo.isar!=1}">
                                                    <div v-for="(item,i) in currAmountList" :key="i" class="d-flex justify-content-around align-items-center col" :class="{'col-sm-auto justify-content-sm-start':roomInfo.isar==1&&!isMultiple}">
                                                        <div class="position-relative me-1 chips rounded-circle" :class="{active:amount==item[0]}" @click="amount=item[0]" role="button">
                                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+item[0]+'.png')" alt="">
                                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(item[0])}}</span>
                                                        </div>
                                                        <button v-if="i==2" @click.stop="isShowMoreAmount=!isShowMoreAmount" class="btn btn-secondary btn-shadow-left text-light">
                                                            <i class="bi bi-grid-fill"></i>
                                                        </button>
                                                        <div v-else class="position-relative me-1 chips rounded-circle" :class="{active:amount==item[1]}" @click="amount=item[1]" role="button">
                                                            <img class="w-3rem rounded-circle chips-shadow" :src="require('@/assets/images/room/chips_'+item[1]+'.png')" alt="">
                                                            <span class="position-absolute start-50 top-50 fw-bold chips-text text-nowrap">{{$root.chipsTxtConvert(item[1])}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- 按钮区 -->
                                                <div v-if="roomInfo.isar!=1&&!isMultiple" class="d-flex justify-content-between text-nowrap order-3 mb-1 mb-sm-0 ms-sm-1 flex-grow-1" :class="{'justify-content-sm-around':!isMultiple,'col':roomInfo.isar!=1}">
                                                    <div class="form-check form-switch d-flex align-items-center border border-1 border-secondary rounded p-1 mb-0">
                                                        <label class="me-1 text-light" for="cboRebateNewXs">{{$t("room.unRebate")}}</label>
                                                        <input class="form-check-input fs-3 m-0" type="checkbox" id="cboRebateNewXs" @change="setRebate($event)" :checked="currRebate==rebate.noRebate">
                                                    </div>
                                                    <button class="btn btn-primary bg-gradient text-light btn-shadow-right px-sm-3" :class="{'mb-sm-1':roomInfo.isar==1}" type="button" @click="submitEvent('multiple')">{{isMultiple?$t("room.singleRoom"):$t("room.multipleRoom")}}</button>
                                                    <button class="btn btn-warning bg-gradient text-light btn-shadow-right px-sm-3" :class="{'disabled':!canClear,'mb-sm-1':roomInfo.isar==1}" :disabled="!canClear" @click="submitEvent('clear')">{{$t("room.clear")}}</button>
                                                    <button class="btn btn-danger bg-gradient text-light btn-shadow-right px-sm-3" :class="{'disabled':!canCancel,'mb-sm-1':roomInfo.isar==1}" :disabled="!canCancel" @click="submitEvent('cancel')">{{$t("room.cancel")}}</button>
                                                    <button class="btn btn-success bg-gradient text-light btn-shadow-right px-sm-3" :class="{'disabled':!canConfirm}" :disabled="!canConfirm" @click="submitEvent('confirm')">{{$t("room.confirm")}}</button>
                                                </div>
                                            </div>
                                            <!-- 提交区 -->
                                            <div :style="{background:(roomInfo.isar==1&&roomInfo.rid==73)?'#067360':''}" :class="{'flex-grow-1 align-items-sm-stretch':isMultiple,'mb-sm-0':roomInfo.isar==1||(roomInfo.isar!=1&&isMultiple),'flex-sm-nowrap':!isMultiple}" class="d-flex flex-wrap align-items-sm-end align-items-lg-stretch position-relative order-1 order-sm-2 text-center mb-2">
                                                
                                                <!-- lsg -->
                                                <div v-if="roomInfo.game==gameIdList.longfong" class="d-flex col-4 flex-column justify-content-between border-solid border-xs border-end-xs-0 border-bottom-xs-0 border-light position-relative flex-grow-1" :class="{'col-sm-auto order-sm-1 me-sm-1 me-md-2 me-lg-3 ms-sm-1 ms-md-2 rounded-sm-10 border-sm-2 border-md-3 border-lg-4':!isMultiple,'border border-end-0 border-bottom-0 bg-success':isMultiple,'coruscate':coruscateArr.indexOf('zd')!=-1}" @click="addAmount('zd',$event)">
                                                    <span class="fs-2 fw-bold flex-grow-1 d-flex flex-column justify-content-center text-nowrap text-shadow-white" :class="[isMultiple?'my-sm-0 my-lg-1 lh-sm-1':'mx-sm-3 mx-md-4']" :style="{'color':color.blue}">{{$t("common.lsg")}}</span>
                                                    <div class="small text-white-50 text-nowrap" :class="[isMultiple?'lh-sm-1 lh-xl-2':'']">
                                                        <div>{{limitred.zd}}</div>
                                                        <div>1:{{odds.zd}}</div>
                                                    </div>
                                                    <div v-for="j,index in chipsConvert(submited.zd+submiting.zd)" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black" :class="{'chips-img-shadow':index==0}">
                                                        <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                        <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                    </div>
                                                    <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                        <span class="d-block small scale" :class="{'scale-2':isScaleAmount.zd}">
                                                            <small class="text-shadow-white">{{submited.zd+submiting.zd>0?submited.zd+submiting.zd:''}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- h -->
                                                <div class="d-flex col-4 flex-column justify-content-between border-solid border-xs border-end-xs-0 border-light position-relative flex-grow-1" :class="{'col-sm-auto mx-sm-1 mx-md-2 mx-lg-3 rounded-sm-10 border-sm-2 border-md-3 border-lg-4':!isMultiple,'border border-end-0 bg-success':isMultiple,'border-bottom-0':roomInfo.game==gameIdList.longfong&&isMultiple,'coruscate':coruscateArr.indexOf('h')!=-1,'order-sm-3':roomInfo.game==gameIdList.longfong&&!isMultiple,'order-2':roomInfo.game!=gameIdList.longfong,'border-bottom-xs-0':roomInfo.game==gameIdList.longfong}" @click="addAmount('h',$event)">
                                                    <span class="fw-bold flex-grow-1 d-flex flex-column justify-content-center text-shadow-white" :class="[roomInfo.game==gameIdList.lh?'fs-xs-2 fs-sm-2 lh-2-4 my-2 my-sm-0 lh-4 lh-sm-base border-sm-0':'',isMultiple?(roomInfo.game==gameIdList.lh?'fs-xs-2 h1-sm lh-2-4 my-2 my-sm-0 my-lg-2':'fs-2 my-sm-0 my-lg-1 lh-sm-1'):(roomInfo.game==gameIdList.lh?'mx-sm-5 mx-md-4 px-sm-3 px-md-5':'fs-2 mx-sm-4 mx-lg-5 px-sm-2 px-md-3 px-lg-0')]" :style="'color:'+color.green">{{$t("common.h")}}</span>
                                                    <div class="small text-white-50 text-nowrap">
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">{{limitred.h}}</div>
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">1:{{odds.h}}</div>
                                                    </div>
                                                    <div v-for="j,index in chipsConvert(submited.h+submiting.h)" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black" :class="{'chips-img-shadow':index==0}">
                                                        <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                        <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                    </div>
                                                    <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                        <span class="d-block small scale" :class="{'scale-2':isScaleAmount.h}">
                                                            <small class="d-block text-shadow-white">{{submited.h+submiting.h>0?submited.h+submiting.h:''}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- fsg -->
                                                <div v-if="roomInfo.game==gameIdList.longfong" class="d-flex col-4 flex-column justify-content-between border-solid border-xs border-bottom-xs-0 border-light position-relative flex-grow-1" :class="{'col-sm-auto order-sm-5 ms-sm-1 ms-md-2 ms-lg-3 me-sm-1 me-md-2 rounded-sm-10 border-sm-2 border-md-3 border-lg-4':!isMultiple,'border border-bottom-0 bg-success':isMultiple,'coruscate':coruscateArr.indexOf('xd')!=-1}" @click="addAmount('xd',$event)">
                                                    <span class="fs-2 fw-bold flex-grow-1 d-flex flex-column justify-content-center text-nowrap text-shadow-white" :class="[isMultiple?'my-sm-0 my-lg-1 lh-sm-1':'mx-sm-3 mx-md-4']" :style="'color:'+color.red">{{$t("common.fsg")}}</span>
                                                    <div class="small text-white-50 text-nowrap" :class="[isMultiple?'lh-sm-1 lh-xl-2':'']">
                                                        <div>{{limitred.xd}}</div>
                                                        <div>1:{{odds.xd}}</div>
                                                    </div>
                                                    <div v-for="j,index in chipsConvert(submited.xd+submiting.xd)" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black" :class="{'chips-img-shadow':index==0}">
                                                        <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                        <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                    </div>
                                                    <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                        <span class="d-block small scale" :class="{'scale-2':isScaleAmount.xd}">
                                                            <small class="d-block text-shadow-white">{{submited.xd+submiting.xd>0?submited.xd+submiting.xd:''}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- long -->
                                                <div class="d-flex flex-column justify-content-between border-solid border-xs border-light position-relative flex-grow-1" :class="{'col-sm-auto rounded-sm-10 border-sm-2 border-md-3 border-lg-4':!isMultiple,'border-1 bg-success':isMultiple,'coruscate':coruscateArr.indexOf('z')!=-1,'order-sm-2 col-6 border-end-xs-0':roomInfo.game==gameIdList.longfong,'border-end-0':roomInfo.game==gameIdList.longfong&&isMultiple,'col-4 order-3':roomInfo.game!=gameIdList.longfong,'me-sm-2':roomInfo.game!=gameIdList.longfong&&!isMultiple}" @click="addAmount('z',$event)">
                                                    <span class="fw-bold flex-grow-1 d-flex flex-column justify-content-center text-shadow-white" :class="[roomInfo.game==gameIdList.lh?'fs-xs-2 fs-sm-2 lh-2-4 my-2 my-sm-0 fs-2 lh-4 lh-sm-base border-sm-0':'',isMultiple?(roomInfo.game==gameIdList.lh?'fs-xs-2 h1-sm lh-2-4 my-2 my-sm-0 my-lg-2':'fs-2 my-sm-0 my-lg-1 lh-sm-1'):(roomInfo.game==gameIdList.lh?'mx-sm-5 mx-md-4 px-sm-3 px-md-5':'fs-2 mx-sm-4 mx-lg-5 px-sm-2 px-md-3 px-lg-0')]" :style="'color:'+color.blue">{{$t("common.l")}}</span>
                                                    <div class="small text-white-50 text-nowrap">
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">{{limitred.z}}</div>
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">1:{{currRebate==rebate.rebate?odds.z:1}}</div>
                                                    </div>
                                                    <div v-for="j,index in chipsConvert(submited.z+submiting.z)" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black" :class="{'chips-img-shadow':index==0}">
                                                        <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                        <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                    </div>
                                                    <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                        <span class="d-block small scale" :class="{'scale-2':isScaleAmount.z}">
                                                            <small class="d-block text-shadow-white">{{submited.z+submiting.z>0?submited.z+submiting.z:''}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- feng -->
                                                <div class="d-flex flex-column justify-content-between border-solid border-xs border-light position-relative flex-grow-1" :class="{'col-sm-auto rounded-sm-10 border-sm-2 border-md-3 border-lg-4':!isMultiple,'border-1 bg-success':isMultiple,'coruscate':coruscateArr.indexOf('x')!=-1,'order-sm-4 col-6':roomInfo.game==gameIdList.longfong,'col-4 order-1 border-end-xs-0':roomInfo.game!=gameIdList.longfong,'border-end-0':roomInfo.game!=gameIdList.longfong&&isMultiple,'ms-sm-2':roomInfo.game!=gameIdList.longfong&&!isMultiple}" @click="addAmount('x',$event)">
                                                    <span class="fw-bold flex-grow-1 d-flex flex-column justify-content-center text-shadow-white" :class="[roomInfo.game==gameIdList.lh?'fs-xs-2 fs-sm-2 lh-2-4 my-2 my-sm-0 fs-2 lh-4 lh-sm-base border-sm-0':'',isMultiple?(roomInfo.game==gameIdList.lh?'fs-xs-2 h1-sm lh-2-4 my-2 my-sm-0 my-lg-2':'fs-2 my-sm-0 my-lg-1 lh-sm-1'):(roomInfo.game==gameIdList.lh?'mx-sm-5 mx-md-4 px-sm-3 px-md-5':'fs-2 mx-sm-4 mx-lg-5 px-sm-2 px-md-3 px-lg-0')]" :style="'color:'+color.red">{{$t("common.fong")}}</span>
                                                    <div class="small text-white-50 text-nowrap">
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">{{limitred.x}}</div>
                                                        <div :class="[roomInfo.game==gameIdList.lh?'lh-xs-3':'',isMultiple?(roomInfo.game==gameIdList.lh?'lh-xs-3 lh-sm-2 lh-xl-4':'lh-sm-1 lh-xl-2'):(roomInfo.game==gameIdList.lh?'':'')]">1:{{currRebate==rebate.rebate?odds.x:1+(roomInfo.game==gameIdList.longfong?'('+$t("room.rebateTxt")+')':'')}}</div>
                                                    </div>
                                                    <div v-for="j,index in chipsConvert(submited.x+submiting.x)" :key="j" :style="'bottom:'+(2*index)+'px !important'" class="position-absolute start-50 translate-middle-x fs-6 mb-4 text-black" :class="{'chips-img-shadow':index==0}">
                                                        <img class="w-2_5rem rounded-circle" :src="require('@/assets/images/room/chips_'+j+'.png')" alt="">
                                                        <span class="position-absolute start-50 top-50 fw-bold chips-text small">{{$root.chipsTxtConvert(j)}}</span>
                                                    </div>
                                                    <div class="position-absolute start-50 translate-middle-x text-black fw-bold fs-6 bottom-0">
                                                        <span class="d-block small scale" :class="{'scale-2':isScaleAmount.x}">
                                                            <small class="d-block text-shadow-white">{{submited.x+submiting.x>0?submited.x+submiting.x:''}}</small>
                                                        </span>
                                                    </div>
                                                </div>
                                                <!-- 更多筹区 -->
                                                <change-amount :class="{'rounded-sm-10':!isMultiple}" :is-show="isShowMoreAmount"></change-amount>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 按钮区 -->
                                    <div v-if="roomInfo.isar==1||isMultiple" class="d-flex justify-content-between text-nowrap order-3 mb-1 mb-sm-0 px-1" :class="{'ps-sm-0 pe-sm-2':isMultiple,'justify-content-sm-around px-sm-0 ms-sm-1':!isMultiple,'flex-sm-column':roomInfo.isar==1&&!isMultiple}">
                                        <div :class="{'d-sm-none':!isMultiple}" class="form-check form-switch d-flex align-items-center border border-1 border-secondary rounded p-1 mb-0">
                                            <label class="me-1 text-light" for="cboRebateNewXs">{{$t("room.unRebate")}}</label>
                                            <input class="form-check-input fs-3 m-0" type="checkbox" id="cboRebateNewXs" @change="setRebate($event)" :checked="currRebate==rebate.noRebate">
                                        </div>
                                        <!-- <button class="btn btn-primary bg-gradient text-light btn-shadow-right" :class="{'mb-sm-1':roomInfo.isar==1&&!isMultiple}" type="button" @click="submitEvent('multiple')">{{isMultiple?$t("room.singleRoom"):$t("room.multipleRoom")}}</button> -->
                                        <button class="btn btn-warning bg-gradient text-light btn-shadow-right" :class="{'disabled':!canClear,'mb-sm-1':roomInfo.isar==1&&!isMultiple}" :disabled="!canClear" @click="submitEvent('clear')">{{$t("room.clear")}}</button>
                                        <button class="btn btn-danger bg-gradient text-light btn-shadow-right" :class="{'disabled':!canCancel,'mb-sm-1':roomInfo.isar==1&&!isMultiple}" :disabled="!canCancel" @click="submitEvent('cancel')">{{$t("room.cancel")}}</button>
                                        <button class="btn btn-success bg-gradient text-light btn-shadow-right" :class="{'disabled':!canConfirm}" :disabled="!canConfirm" @click="submitEvent('confirm')">{{$t("room.confirm")}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 牌区 -->
                        <div v-if="showPokerArr.length>0" class="d-flex position-absolute top-xs-0 top-sm-26 index-1 w-xs-100 h-xs-100 align-items-center align-items-sm-start justify-content-center justify-content-sm-start bg-xs-dark bg-opacity-75 rounded-sm-10 text-start">
                            <div v-for="(item,i) in showPokerArr" :key="item" :class="[roomInfo.game==gameIdList.bac?'order-'+(showPokerArr.length-i):'']" :style="{'background':(i==0?'linear-gradient('+(roomInfo.game==gameIdList.bac?90:270)+'deg,#00315d,rgb(0, 105, 197) 40%)':'linear-gradient('+(roomInfo.game==gameIdList.bac?270:90)+'deg,#910000, rgb(251, 36, 1) 40%)')}" class="d-flex flex-column rounded">
                                <div class="text-light text-center fw-bold rounded position-relative pt-1 fs-xs-5">
                                    <span>{{i==0?$t("common.l"):$t("common.fong")}}</span>
                                    <i class="position-absolute fw-bold fs-xs-1 fs-sm-4 lh-1" :class="{'end-0':(roomInfo.game==gameIdList.bac&&i!=0)||(roomInfo.game!=gameIdList.bac&&i==0),'start-0':(roomInfo.game==gameIdList.bac&&i==0||roomInfo.game!=gameIdList.bac&&i!=0)}" :style="{'color':i==0?color.red:color.blue}">{{ roomInfo.game==gameIdList.bac?(i==0?'S':'V'):(i==0?'V':'S') }}</i>
                                    <span v-if="isPokerWin(showPokerArr,i)" class="bi bi-trophy-fill text-yellow position-absolute translate-middle-y top-50 big-scale-small fs-xs-1 fs-sm-4" :class="{'start-0':(roomInfo.game==gameIdList.bac&&i!=0)||(roomInfo.game!=gameIdList.bac&&i==0),'end-0':(roomInfo.game==gameIdList.bac&&i==0||roomInfo.game!=gameIdList.bac&&i!=0)}"></span>
                                </div>
                                <div class="rounded p-1 d-flex flex-wrap justify-content-between align-content-around position-relative w-10rem w-sm-7rem h-11rem h-sm-8rem">
                                    <!-- 牌 -->
                                    <div class="d-flex align-items-center justify-content-center" :class="[item.length==3&&j==2?'w-100 order-1':'order-2']" v-for="(val,j) in item" :key="val">
                                        <div v-if="val" :class="[$root.getPokerByNum(val)['color'],((i+1)==currPokerXY[0]&&(j+1)==currPokerXY[1])?'rotate':'',j==2?'':'flex-column']" class="d-flex bg-light rounded p-1 h-100">
                                            <span class="fw-bold lh-1 fs-xs-5 small" :class="{'order-1 text-vertical':j==2}">{{ $root.getPokerByNum(val)["name"] }}</span>
                                            <div class="d-flex flex-grow-1" :class="[j==2?'py-3 py-sm-2 pe-2 pe-sm-1 ps-3 ps-sm-2':'px-3 px-sm-2 pt-2 pt-sm-1 pb-3 pb-sm-2']">
                                                <i :class="[$root.getPokerByNum(val)['shape'],j==2?'rotate-90':'']" class="bi d-flex align-items-center justify-content-center fs-xs-1 fs-sm-4 flex-grow-1"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="position-absolute top-40 start-50 translate-middle init-rotate-345 border-double border-dark px-1 rounded fw-bold text-nowrap big-scale-small">{{ getPoint(item) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 池额 -->
                    <div class="position-absolute top-0 end-0 d-flex flex-column bg-dark text-light bg-opacity-50 px-3 py-1 rounded me-2 mt-2 mt-sm-5 fw-bold index-1">
                        <div class="text-center border-bottom">{{$t("video.pool")}}</div>
                        <div class="d-flex text-end small justify-content-between">
                            <div class="d-flex flex-column me-3 ms-2">
                                <span :style="'color:'+color.blue">{{$t("common.l")}}</span>
                                <span :style="'color:'+color.green">{{$t("common.h")}}</span>
                                <span :style="'color:'+color.red">{{$t("common.fong")}}</span>
                                <span :style="'color:'+color.blue">{{$t("common.lsg")}}</span>
                                <span :style="'color:'+color.red">{{$t("common.fsg")}}</span>
                            </div>
                            <div class="d-flex flex-column me-2">
                                <span :style="'color:'+color.blue">{{pool.z}}</span>
                                <span :style="'color:'+color.green">{{pool.h}}</span>
                                <span :style="'color:'+color.red">{{pool.x}}</span>
                                <span :style="'color:'+color.blue">{{pool.zd}}</span>
                                <span :style="'color:'+color.red">{{pool.xd}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 路单统计区 -->
            <div v-if="!isMultiple" class="d-flex flex-wrap justify-content-between text-light pe-2 small bg-gradient">
                <div class="ps-2">{{$t("common.l")}}：{{pearlCount.z}}</div>
                <div class="ps-2">{{$t("common.fong")}}：{{pearlCount.x}}</div>
                <div class="ps-2">{{$t("common.h")}}：{{pearlCount.h}}</div>
                <div class="ps-2">{{$t("common.lsg")}}：{{pearlCount.zd}}</div>
                <div class="ps-2">{{$t("common.fsg")}}：{{pearlCount.xd}}</div>
                <!-- <div class="d-flex col-12 col-sm-auto fw-bold">
                    <div class="ps-2 col-6 col-sm-auto d-flex">
                        <span :style="'color:'+color.blue">{{$t("room.nextl")}}：</span>
                        <label class="d-flex align-items-center text-transparent">
                            <span class="rounded-circle w-1rem h-1rem border-2 border-solid me-2" :style="nextXHollowStyle"></span>
                            <span class="h-1rem border-1 border-top-0 border-left-0 border-bottom-0 rotate-30 border-solid me-2" :style="nextXBevelStyle"></span>
                            <span class="rounded-circle w-1rem h-1rem border-3" :style="nextXSolidStyle"></span>
                        </label>
                    </div>
                    <div class="ps-2 col-6 col-sm-auto d-flex justify-content-end">
                        <span class="text-danger">{{$t("room.nextf")}}：</span>
                        <label class="d-flex align-items-center text-transparent">
                            <span class="rounded-circle w-1rem h-1rem border-2 border-solid me-2" :style="nextZHollowStyle"></span>
                            <span class="h-1rem border-1 border-top-0 border-left-0 border-bottom-0 rotate-30 border-solid me-2" :style="nextZBevelStyle"></span>
                            <span class="rounded-circle w-1rem h-1rem border-3" :style="nextZSolidStyle"></span>
                        </label>
                    </div>
                </div> -->
            </div>
            <!-- 路单区 -->
            <div v-if="!isMultiple" class="flex-grow-1 d-flex h-0 position-relative" :class="[(gameModel==GAME_MODEL.normal)?'flex-sm-grow-0 h-sm-6rem':'overflow-hidden']">
                <!-- 问路 -->
                <div class="d-flex flex-column text-light text-center w-2rem text-nowrap small">
                    <div @click="nextPrediction('B')" :style="'background:'+color.blue+';border-color:'+color.blue" class="d-flex justify-content-center align-items-center col text-vertical small bg-highlight border-_3 border-style-outset" role="button">{{$t("room.willl")}}</div>
                    <div @click="nextPrediction('D')" :style="'background:'+color.red+';border-color:'+color.red" class="d-flex justify-content-center align-items-center col text-vertical small bg-highlight border-_3 border-style-outset" role="button">{{$t("room.willf")}}</div>
                </div>
                <!-- 路单 -->
                <div :class="{'flex-sm-nowrap':pearlModel==1,'flex-column':pearlModel==2}" class="flex-grow-1 d-flex flex-wrap w-0">
                    <div :class="{'col-12 col-sm-4 h-xs-33 h-sm-100':pearlModel==1,'h-xs-33 col-sm-3 h-sm-100':pearlModel==2}" class="position-relative">
                        <canvas id="textBigPearl" class="bg-light d-block mx-auto min-w-100 min-h-100 mw-100 mh-100" v-set-size="6" data-canvas-room="6" width="0" height="0" @mousemove.stop="showPoker($event,1)"></canvas>
                        <div class="bg-dark bg-opacity-25 position-absolute" :style="pearlLayerStyle" @mouseout.stop="showPoker($event,0)"></div>
                        <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                            <div class="d-flex flex-column justify-content-center">
                                <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.big_text)" role="button"></i>
                            </div>
                        </div>
                    </div>
                    <div :class="{'col-12 col-sm-4 h-xs-33 h-sm-100':pearlModel==1,'h-xs-33 col-sm-9 h-sm-50':pearlModel==2}" class="position-relative">
                        <canvas id="bigPearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="6" data-canvas-room="6" width="0" height="0"></canvas>
                        <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                            <div class="d-flex flex-column justify-content-center">
                                <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.big_hollow)" role="button"></i>
                            </div>
                        </div>
                    </div>
                    <div :class="{'col-12 col-sm-4 h-xs-33 h-sm-100':pearlModel==1,'h-xs-33 col-sm-9 h-sm-50':pearlModel==2}" class="position-relative">
                        <canvas id="pointPearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" :v-set-size="pearlModel==1?4:2" :data-canvas-room="pearlModel==1?4:2" width="0" height="0"></canvas>
                        <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                            <div class="d-flex flex-column justify-content-center">
                                <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.result_point)" role="button"></i>
                            </div>
                        </div>
                    </div>
                    <!-- <div :class="{'col-12 col-sm-6 h-xs-50 h-sm-100':pearlModel==1,'h-xs-33 col-sm-9 h-sm-50':pearlModel==2}" class="d-flex flex-column">
                        <div class="d-flex h-50">
                            <div class="col-6 position-relative">
                                <canvas id="smallHollowPearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                                <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                                    <div class="d-flex flex-column justify-content-center">
                                        <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.small_hollow)" role="button"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 position-relative">
                                <canvas id="smallSolidPearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                                <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                                    <div class="d-flex flex-column justify-content-center">
                                        <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.small_solid)" role="button"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex h-50">
                            <div class="col-6 position-relative">
                                <canvas id="bevelPearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                                <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                                    <div class="d-flex flex-column justify-content-center">
                                        <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.bevel)" role="button"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 position-relative">
                                <canvas id="threePearl" class="bg-light d-block min-w-100 min-h-100 mw-100 mh-100" v-set-size="3" data-canvas-room="3" width="0" height="0"></canvas>
                                <div v-if="isShowPearlMoreLayer" class="d-flex justify-content-center bg-dark bg-opacity-75 position-absolute top-0 start-0 w-100 h-100 text-light hover-bg-opacity-50">
                                    <div class="d-flex flex-column justify-content-center">
                                        <i class="d-flex bi bi-arrows-fullscreen p-2 fs-5" @click="showMorePearl(pearlType.big_three)" role="button"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- 功能 -->
                <div class="d-flex flex-column flex-sm-row small text-light">
                    <!-- 切换模式 -->
                    <div v-if="!isShowPearlMoreLayer" class="col d-flex flex-column justify-content-center align-items-center px-1 bg-dark bg-opacity-75 border-_3 border-style-outset border-dark small bg-gradient" @click="pearlModel=pearlModel==1?2:1" role="button">
                        <i class="bi bi-arrow-left-right lh-1 mb-lg-1"></i>
                        <div class="d-flex flex-column align-items-center">
                            <span class="text-vertical">{{$t('room.toggleMode')}}</span>
                        </div>
                    </div>
                    <!-- 查看全部按钮 -->
                    <div class="col d-flex flex-column justify-content-center align-items-center px-1 bg-dark bg-opacity-75 border-_3 border-style-outset border-dark small bg-gradient" @click="isShowPearlMoreLayer=!isShowPearlMoreLayer" role="button">
                        <i v-show="!isShowPearlMoreLayer" class="bi bi-search lh-1 mb-lg-1"></i>
                        <i v-show="isShowPearlMoreLayer" class="bi bi-x-octagon lh-1 mb-lg-1"></i>
                        <div class="d-flex flex-column align-items-center">
                            <span v-show="!isShowPearlMoreLayer" class="text-vertical">{{$t("room.viewAll")}}</span>
                            <span v-show="isShowPearlMoreLayer" class="text-vertical">{{$t("common.close")}}</span>
                        </div>
                    </div>
                </div>
                <!-- 查看所有路单 -->
                <div :class="{'d-none':pearlMoreType==null}" class="position-absolute start-0 top-0 w-100 h-100">
                    <div class="w-100 h-100 bg-light">
                        <canvas id="fullPearl" v-move-x class="bg-light d-block min-w-100 min-h-100 mh-100 position-absolute end-0" width="0" height="0"></canvas>
                    </div>
                    <div class="position-absolute top-50 start-0 translate-middle-y p-2">
                        <i class="bi bi-caret-left-square-fill opacity-50 fs-1" @click="movePearlMore(-1)" role="button"></i>
                    </div>
                    <div class="position-absolute top-50 end-0 translate-middle-y p-2">
                        <i class="bi bi-caret-right-square-fill opacity-50 fs-1" @click="movePearlMore(1)" role="button"></i>
                    </div>
                    <div class="position-absolute bottom-0 start-50 translate-middle-x p-2">
                        <i class="d-flex bi bi-x-circle-fill opacity-75 fs-2" @click="pearlMoreType=null" role="button"></i>
                    </div>
                </div>
            </div>
            <!-- 换台区 -->
            <change-room v-if="!isMultiple" :class="{'me-sm-0':roomInfo.isar==1}" :room-list="roomList" :count-list="countList" :pearl-obj="pearlObj" @get-room-pearl="getRoomPearl"></change-room>
            <!-- 音效 -->
            <audio-player v-show="false" ref="resultAudioPlayer" :isLoop="false" :isAutoPlayNext="false" :muted="!audioSwitch.resultAudio" :audio-list="resultAudioList.map(elm => elm.url)" />
            <!-- 弹出框 -->
        </div>
    </div>
</template>
<script>
import {COMMAND,COLOR, GAME_ID,PEARL_TYPE,PEARL_CODE,REBATE_TYPE,IS_DEBUG,LAYER_TYPE,SUBMIT_RESULT,CANCEL_RESULT, IS_REBATE,RESULT,RATIO,AMOUTN_ARR,MUTATION_TYPE,GAME_CODE, GAME_MODEL} from "@/assets/js/config.js"
import mediaVideo from "@/components/public/video.vue";
import roomList from '@/components/public/roomList.vue';
import navbarVertical from '@/components/public/navbarVertical.vue';
import navbar from '@/components/public/navbar.vue';
import notice from '@/components/public/notice.vue';
import changeRoom from '@/components/public/changeRoom.vue'
import changeAmount from '@/components/public/changeAmount.vue'
import { useLayer } from '@/assets/js/useLayer';
import { getDeviceType } from '@/assets/js/common.js'
export default {
    components: { 
        mediaVideo,
        roomList,
        changeRoom,
        navbarVertical,
        navbar,
        notice,
        changeAmount
    },
    directives: {
        'set-size': {
            mounted(elem,binding){
                let _this=binding.instance;
                //设置画布大小
                _this.$root.setCanvasSize(elem);
                //绘制画布表格
                _this.$root.drawCanvasTable(elem,binding.value,false,elem.id=="pointPearl"?2:1);
            }
        },
        'move-x':{
            mounted(elem,binding){
                let clickFn=(e,fn)=>{
                    elem.classList.add("untransition");
                    let clickX=e.pageX||e.targetTouches[0].pageX;//按下的x坐标
                    let width=getComputedStyle(elem).width.replace('px','');//总宽度
                    let parentWidth=getComputedStyle(elem.parentNode).width.replace('px','');//获取一版宽度
                    let left=Number(getComputedStyle(elem).left.replace('px',''));//左边距
                    fn(clickX,width,parentWidth,left);
                }
                let moveFn=(e,clickX,width,parentWidth,left)=>{
                    let moveX=e.pageX||e.targetTouches[0].pageX;//移动到的x坐标
                    let moveDistance=moveX-clickX;//移动的距离
                    let newLeft=left+moveDistance;//新的左边距
                    if(newLeft>0){//左边距不能大于0
                        newLeft=0;
                    }else if(Math.abs(newLeft)>width-parentWidth){//左边距不能小于总宽度-一版的宽度
                        newLeft=-(width-parentWidth);
                    }
                    elem.style.left=newLeft+"px";//新左边距=旧左边距+移动的距离
                }
                elem.onmouseup=(e1)=>{
                    elem.classList.remove("untransition");
                }
                elem.onmousedown=(e1)=>{
                    clickFn(e1,(clickX,width,parentWidth,left)=>{
                        document.onmousemove=(e2)=>{
                            moveFn(e2,clickX,width,parentWidth,left);
                        }
                    })
                }
                elem.ontouchstart=(e1)=>{
                    clickFn(e1,(clickX,width,parentWidth,left)=>{
                        document.ontouchmove=(e2)=>{
                            moveFn(e2,clickX,width,parentWidth,left);
                        }
                    })
                }
            }
        }
    },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            GAME_MODEL,
            pearlType:PEARL_TYPE,//获取路单类型配置
            color:COLOR,//获取颜色配置
            gameIdList:GAME_ID,//获取游戏id列表配置
            rebate:REBATE_TYPE,//获取佣金方式配置
            pearlCount:{z:0,x:0,h:0,zd:0,xd:0},//统计
            pearlArr:[],//台面路单列表
            virtualPearlArr:[],//台面问路路单列表
            nextZHollowStyle:"",//下局预测Z空心样式
            nextZBevelStyle:"",//下局预测Z斜线样式
            nextZSolidStyle:"",//下局预测Z实心样式
            nextXHollowStyle:"",//下局预测X空心样式
            nextXBevelStyle:"",//下局预测X斜线样式
            nextXSolidStyle:"",//下局预测X实心样式
            odds:{z:"0",x:"0",h:"0",zd:"0",xd:"0"},//赔率
            limitred:{z:"0-0",x:"0-0",h:"0-0",zd:"0-0",xd:"0-0"},//限红
            pool:{z:0,x:0,zd:0,xd:0,h:0},//池
            coruscateArr:[],//闪烁位集合，存放将要闪烁的位置
            currRebate:REBATE_TYPE.rebate,//当前佣金模式
            amount:10,//选中额度
            currAmountListSort:[10,50,100,500,1000,5000,10000],//排序后的选中额度列表
            submited:{z:0,x:0,h:0,zd:0,xd:0},//当前模式已提交的额度列表
            submiting:{z:0,x:0,h:0,zd:0,xd:0},//当前模式将要提交的额度列表
            submitedCount:0,//全部模式已提交的总额
            canClear:false,//是否可以点击清除
            canCancel:false,//是否可以点击取消
            canConfirm:false,//是否可以点击确定
            pearlObj: {
                id:0,//台面id
                pearl:[]//路单数据
            },//要重绘路单的对象
            countList:[],//路单统计列表
            isMultiple:false,//是否显示多台
            isShowNextPrediction:false,//当前是否显示着问路
            resultAudioList:[
                {
                    url:require('@/assets/media/baccarat/win_draw.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_h.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_l.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_x.mp3')
                },
                {
                    url:require('@/assets/media/baccarat/win_z.mp3')
                }
            ],//开结果音效
            pokerArr:[],//当前台面牌组
            showPokerArr:[],//要显示的牌组
            currPokerXY:[0,0],//当前要翻转的扑克
            pearlLayerStyle:{
                width:0,
                height:0,
                left:0,
                top:0,
                display:'none'
            },//鼠标移上路单的遮罩层
            point:[0,0],//鼠标在路单上的坐标点
            videoList:[],//视频线路地址，当前支持最多两个
            isShowMoreAmount:false,//是否显示更多筹码
            isShowPoker:true,//鼠标移入画布是否显示牌
            isShowPokerCompare:true,//是否显示牌的大小比较
            isModuleReady:false,//页面内组件是否加载完成
            isShowPearlMoreLayer:false,//是否显示查看全部路单遮罩层
            pearlMoreType:null,//当前显示的全部路单数据的类型
            pearlModel:1,//路子模式（1:模式1 2:模式2）
            readyVideoIdArr:[],//已加载好的视频id

            //新版台参数
            isScale:false,//是否放大提交区和视频区
            isScaleAmount:{z:false,x:false,h:false,zd:false,xd:false},//是否放大码额
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        roomInfo() {
            return this.$store.state.roomInfo;
        },
        tabGame(){
            return this.$store.state.tabGame;
        },
        currVideoLine(){
			return this.$store.state.currVideoLine;
		},
        audioSwitch(){
            return this.$store.state.audioSwitch;
        },
        gameModel(){
            return this.$store.state.gameModel;
        },
        currAmountList(){
            return this.$store.state.currAmountList;
        },
        roomList(){
            return this.$store.state.roomList;
        }
    },
    mounted() {
        layer.closeAll();
        this.init();
        this.setWsMessage();
        addEventListener("resize",this.setResize);
    },
    unmounted() {
        removeEventListener("resize",this.setResize);
        delete this.$root.wsMsgObj["longfong"];
    },
    methods: {
        init(){
            //获取台面视频
            if(this.currVideoLine==""){
                useLayer({str:this.$t("video.noVideoLine")});
            }else{
                this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.id,this.userInfo.guid);
            }
            //获取赔率
            this.$emit("ws-send",COMMAND.bac_getodds,this.roomInfo.id,this.userInfo.id,this.userInfo.guid);
            //获取限红
            this.$emit("ws-send",COMMAND.bac_getlimitred,this.userInfo.id,this.userInfo.guid);
            //获取各位置已提交额度
            this.$emit("ws-send",COMMAND.bac_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
            //获取台面路单
            this.$emit("ws-send",COMMAND.all_getroompearl, this.roomInfo.game,this.roomInfo.id,200,"table", this.userInfo.id, this.userInfo.guid);
            //获取台面扑克
            this.$emit("ws-send",COMMAND.bac_getroompoker, this.roomInfo.id,this.roomInfo.boot,200,this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 设置websocket响应事件
         */
        setWsMessage(){
            this.$root.wsMsgObj["longfong"]=(data)=>{
                switch (data.command) {
                    case COMMAND.all_getroompearl:
                        if(data.id==this.roomInfo.id&&!this.isMultiple){//获取的是当前台面的数据并且非多台模式
                            this.pearlArr=data.pearl;
                        }
                        this.pearlObj={
                            id:data.id,
                            pearl:data.pearl
                        }
                        this.countList[data.id]=data.count;
                        break;
                    case COMMAND.all_getvideo:
                        let videoArr=data.video;
                        let list=[];//拼装好的视频地址数组
                        for (let i = 0; i < videoArr.length; i++) {
                            list.push(data.flvline+'/'+videoArr[i]+'.flv');
                        }
                        this.videoList=list;
                        break;
                    case COMMAND.bac_getodds:
                        this.odds={
                            z:data.odds.z,
                            x:data.odds.x,
                            h:data.odds.h,
                            zd:data.odds.zd,
                            xd:data.odds.xd
                        }
                        break;
                    case COMMAND.bac_getlimitred:
                        this.limitred={
                            z:data.limitred.z,
                            x:data.limitred.x,
                            h:data.limitred.h,
                            zd:data.limitred.zd,
                            xd:data.limitred.xd
                        }
                        this.autoAmount();
                        // //配置额度规则：永远保留10，最高小于两千保留20，最高小于一万保留50 最低在一千以下则保留100，
                        // let max=data.limitred.z.split('-')[1];
                        // if (max<=2000) {
                        //     this.currAmountListSort=[10,20,50,100,200,500,1000];
                        // }else if(max>2000&&max<=5000){
                        //     this.currAmountListSort=[10,50,100,200,500,1000,2000];
                        // }else if(max>5000&&max<=10000){
                        //     this.currAmountListSort=[10,100,200,500,1000,2000,5000];
                        // }else if(max>10000&&max<=20000){
                        //     this.currAmountListSort=[10,100,200,500,1000,5000,10000];
                        // }else if(max>20000&&max<=100000){
                        //     this.currAmountListSort=[10,100,500,1000,5000,10000,20000];
                        // }

                        //设置额度，优先取与限红一致的额度，个数超出则去除，不够则补足
                        // let limitredArr=Object.values(this.limitred).map(v=>v.split('-')).flat(Infinity);
                        // let noRepeatArr=[];//去掉重复项后的限红数组
                        // let chooseArr=[];//筛选出的额度数组
                        // for (let i = 0; i < limitredArr.length; i++) {//限红去重
                        //     noRepeatArr.indexOf(limitredArr[i])==-1&&noRepeatArr.push(limitredArr[i]);
                        // }
                        // for (let i = 0; i < this.amountList.length; i++) {//挑选与限红一样的额度
                        //     noRepeatArr.indexOf(String(this.amountList[i]))!=-1&&chooseArr.push(this.amountList[i]);
                        // }
                        // if(chooseArr.length>7){//多于7个
                        //     this.currAmountListSort=chooseArr.slice(0,7);//取前5个
                        // }else if(chooseArr.length<7){//少于7个，等于7个不考虑
                        //     for (let i = 0; i < this.amountList.length; i++) {//用额度列表中的额度补足
                        //         chooseArr.indexOf(this.amountList[i])==-1&&chooseArr.push(this.amountList[i]);
                        //         if(chooseArr.length==7){
                        //             break;
                        //         }
                        //     }
                        //     this.currAmountListSort=chooseArr;
                        // }
                        // this.currAmountListSort.sort((a,b)=>a-b);
                        break;
                    case COMMAND.all_endgame:
                        data.id==this.roomInfo.id&&this.resetAmount("submiting");
                        break;
                    case COMMAND.bac_gameresult:
                        if(data.id==this.roomInfo.id){
                            this.currPokerXY=[0,0];
                            this.updatePearl(this.pearlArr,data.pearl);
                            this.setCoruscate(data.pearl);
                            this.isShowPokerCompare=true;
                            if(data.zpoker.length>0&&data.xpoker.length>0){//有牌
                                this.showPokerArr=[data.zpoker,data.xpoker];
                                this.pokerArr.push(this.showPokerArr);
                                setTimeout(() => {
                                    this.showPokerArr=[];
                                }, 5000);
                            }
                        }
                        //切换台面/多台的台面列表中存在，则需要重新获取
                        typeof this.roomList.find(v=>v.id==data.id)!="undefined"&&this.$emit("ws-send",COMMAND.all_getroompearl,this.roomInfo.game,data.id,200,"list", this.userInfo.id, this.userInfo.guid);//台面存在且可见
                        break;
                    case COMMAND.all_getcurrpoker:
                        if(data.id==this.roomInfo.id){
                            this.isShowPoker=false;
                            this.isShowPokerCompare=false;
                            this.showPokerArr=data.poker;
                            this.currPokerXY=data.index;
                        }
                        break;
                    case COMMAND.all_settlement:
                        if(data.id==this.roomInfo.id){
                            this.submitedCount>0&&this.$emit("ws-send",COMMAND.all_getgain,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);//有提交过才需要获取输赢
                            this.resetAmount("all");
                            this.submitedCount=0;
                            this.isShowPoker=true;
                        }
                        break;
                    case COMMAND.all_getgain:
                        useLayer({str:(data.win > Math.abs(data.lose)? this.$t("common.win"):this.$t("common.lose"))+Math.abs(data.win - data.lose)});
                        break;
                    case COMMAND.bac_receivepool:
                        data.id==this.roomInfo.id&&(this.pool=data.pool);
                        break;
                    case COMMAND.bac_submitbet:
                        switch (data.result) {
                            case SUBMIT_RESULT.success:
                                useLayer({str:this.$t("common.subSuccess")});
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        realCoin:data.realmoney
                                    }
                                })
                                for (let i in this.submited) {//将本次提交的额度追加到已提交的额度中
                                    this.submited[i]+=this.submiting[i];
                                    this.submitedCount+=this.submiting[i];
                                }
                                this.resetAmount("submiting");//清空将提交额度列表
                                break;
                            case SUBMIT_RESULT.ban:
                                useLayer({str:this.$t("common.subBan")});
                                break;
                            case SUBMIT_RESULT.unmoney:
                                useLayer({str:this.$t("common.unMoney")});
                                break;
                            case SUBMIT_RESULT.countdown:
                                useLayer({str:this.$t("common.subCountdown")});
                                break;
                            case SUBMIT_RESULT.outside:
                                useLayer({str:this.$t("common.unLimit")});
                                break;
                            case SUBMIT_RESULT.service:
                                useLayer({str:this.$t("common.service")});
                                break;
                            case SUBMIT_RESULT.malice:
                                useLayer({str:""});
                                break;
                            default:
                                useLayer({str:this.$t("common.subErr")});
                                break;
                        }
                        break;
                    case COMMAND.all_cancelbet:
                        if(data.result==CANCEL_RESULT.success){
                            this.resetAmount("all");
                            this.submitedCount=0;
                            this.$store.commit({
                                type:MUTATION_TYPE.setUserInfo,
                                userInfo:{
                                    realCoin:data.realmoney
                                }
                            })
                            useLayer({str:this.$t("common.cancelSuccess")});
                        }else{
                            useLayer({str:this.$t("common.cancelErr")});
                        }
                        break;
                    case COMMAND.all_changeboot:
                        if(data.id==this.roomInfo.id){
                            this.pearlArr=[];
                            this.pokerArr=[];
                            this.roomInfo.boot=data.boot;
                            this.roomInfo.innings=data.innings;
                        }
                        break;
                    case COMMAND.bac_getbetdetail:
                        this.submited={
                            z:Number(data.detail[this.currRebate].z),
                            x:Number(data.detail[this.currRebate].x),
                            h:Number(data.detail[this.currRebate].h),
                            zd:Number(data.detail[this.currRebate].zd),
                            xd:Number(data.detail[this.currRebate].xd)
                        }
                        this.submitedCount= Object.values(data.detail[0]).concat(Object.values(data.detail[1])).reduce((prev,val)=>prev+val);
                        break;
                    case COMMAND.all_entertable:
                        if(data.result==RESULT.enterTable.success){
                            let roomItem=this.roomList.find(v=>v.id==data.id);
                            if(this.tabGame==GAME_ID.longfong){//与当前游戏一致则只修改参数
                                this.$root.$refs.startAudioPlayer.pause();
                                this.$root.$refs.endAudioPlayer.pause();
                                this.$root.$refs.tenAudioPlayer.pause();
                                this.$refs.resultAudioPlayer.pause();
                                //更改台面信息
                                this.$store.commit({
                                    type:MUTATION_TYPE.setRoomInfo,
                                    roomInfo:roomItem
                                })
                                this.pokerArr=[];
                                this.isShowPearlMoreLayer=false;
                                this.pearlMoreType=null;
                                data.iscommission==IS_REBATE.false&&(this.currRebate=REBATE_TYPE.rebate);//不能免佣则重置佣金模式为非免佣
                                //获取台面路单
                                this.$emit('ws-send', COMMAND.all_getroompearl, roomItem.game,data.id,200,"table", this.userInfo.id, this.userInfo.guid);
                                //获取台面视频
                                if(this.currVideoLine==""){
                                    useLayer({str:this.$t("video.noVideoLine")});
                                }else{
                                    this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,this.currVideoLine,this.userInfo.id,this.userInfo.guid);
                                }
                                //获取各位置已提交额度
                                this.$emit("ws-send",COMMAND.bac_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
                                //获取赔率
                                this.$emit("ws-send",COMMAND.bac_getodds,this.roomInfo.id,this.userInfo.id,this.userInfo.guid);
                                //获取台面扑克
                                this.$emit("ws-send",COMMAND.bac_getroompoker, this.roomInfo.id,this.roomInfo.boot,200,this.userInfo.id, this.userInfo.guid);
                            }else{//不一致则进行页面跳转
                                this.$store.commit({
                                    type:MUTATION_TYPE.setRoomInfo,
                                    roomInfo:roomItem
                                })
                                this.$root.goPage(GAME_CODE[this.tabGame]);
                            }
                        }
                        break;
                    case COMMAND.bac_getroompoker:
                        let arr=data.list;
                        for (let i = 0; i < arr.length; i++) {
                            arr[i].zp.length>0&&arr[i].xp.length>0&&this.pokerArr.push([arr[i].zp,arr[i].xp]);
                        }
                        //绘制画布表格
                        let elem=document.getElementById("pointPearl");
                        let val=elem.dataset.canvasRoom;//获取data-canvas-room属性的值
                        //绘制画布表格
                        let obj = this.$root.drawCanvasTable(elem,val,false,elem.id=="pointPearl"?2:1);
                        this.drawOrderPearl(obj,this.pearlArr,PEARL_TYPE.result_point);
                        break;
                    case COMMAND.all_refresh:
                        data.id==this.roomInfo.id&&this.$emit("ws-send",COMMAND.all_getroompearl, data.game,data.id,200,"table", this.userInfo.id, this.userInfo.guid);
                        break;
                    case COMMAND.cor_gameresult:
                        this.tabGame==GAME_ID.cor&&this.$emit("ws-send",COMMAND.all_getroompearl, this.tabGame,data.id,this.$root.columnList[data.id],"list", this.userInfo.id, this.userInfo.guid);
                        break;
                }
            }
        },
        /**
         * 绘制顺序路
         * @param {Object} elemObj 使用drawCanvasTable绘制画布表格返回的对象
         * @param {Array} pearlArr 路单数据
         * @param {PEARL_TYPE} type 路单类型
         */
        drawOrderPearl(elemObj,pearlArr,type){
            let arr=JSON.parse(JSON.stringify(pearlArr));//深克隆一份数组防止操作到原数组
            let ctx = elemObj.elem.getContext('2d');
            let radius=2.6;//半径比例
            let cellHeight = elemObj.cellHeight;
            let cellWidth = elemObj.cellWidth;
            let xCode=PEARL_CODE.F;
            let zCode=PEARL_CODE.L;
            ctx.lineWidth = cellWidth * .12;//边框粗细
            ctx.textBaseline = 'middle';
            if(type==PEARL_TYPE.big_three){
                arr=arr.flat(Infinity).filter((val)=>{
                    return PEARL_CODE.H.indexOf(val.substring(0,1))==-1;//转一维数组后去掉和
                })
                arr=arr.slice(-(elemObj.column - 1) * 3 - arr.length % 3);//取倒数的数据
                for (var i = 0; i < arr.length; i++) {
                    //设置圆圈颜色
                    var letter = arr[i].substr(0, 1);//获取首字母
                    if (xCode.indexOf(letter)!=-1) {
                        ctx.fillStyle = COLOR.blue;//填充颜色
                        ctx.strokeStyle = COLOR.blue;
                    } else if (zCode.indexOf(letter)!=-1) {
                        ctx.fillStyle = COLOR.red;
                        ctx.strokeStyle = COLOR.red
                    }
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 3) - 1) * cellWidth + cellWidth / 2, (i % 3) * cellHeight + cellHeight / 2, cellWidth / radius, 0, 2 * Math.PI);
                    ctx.stroke();
                }
            }else if(type==PEARL_TYPE.big_text){
                var text = '';//填充文本
                var countArr = [0, 0, 0, 0, 0];//庄，闲，和，庄对，闲对 的统计
                radius=2.3;
                ctx.font = 'bold '+cellWidth * .5 + 'px SimHei';
                arr=arr.flat(Infinity);
                arr=arr.slice(-(elemObj.column - 1) * 6 - arr.length % 6);//转一维数组后取倒数的数据
                for (let i = 0; i < arr.length; i++) {
                    // 创建渐变
                    let grd=ctx.createRadialGradient(
                        (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2,
                        (i % 6) * cellHeight + cellHeight / 2,
                        cellWidth / radius,
                        (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2-(cellWidth / radius/2),//x坐标减去二分之一半径
                        (i % 6) * cellHeight + cellHeight / 2-(cellWidth / radius/2),
                        0);
                    //填充圆圈
                    var letter = arr[i].substr(0, 1);//获取首字母
                    let color;
                    let isSix=this.gameModel==GAME_MODEL.tel?arr[i].substr(-1)=="0":false;//是否是幸运6(电话模式有效)
                    ctx.font =isSix?('bold '+cellWidth * .4 + 'px SimHei'):('bold '+cellWidth * .5 + 'px SimHei');
                    if (PEARL_CODE.L.indexOf(letter)!=-1) {
                        color = COLOR.blue;//填充颜色
                        text = this.$t("common.l");
                    } else if (PEARL_CODE.F.indexOf(letter)!=-1) {
                        // color = isSix?"#ff1e88":COLOR.red;
                        color = COLOR.red;
                        text = this.$t("common.fong");
                    } else if (PEARL_CODE.HE.indexOf(letter)!=-1) {
                        color = COLOR.green;
                        text = this.$t("common.h");
                    }
                    grd.addColorStop(0,color);
                    grd.addColorStop(1,COLOR.ray);
                    // grd.addColorStop(1,"#ccc");
                    ctx.fillStyle = grd;
                    ctx.beginPath();
                    ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2, (i % 6) * cellHeight + cellHeight / 2, cellWidth / radius, 0, 2 * Math.PI);
                    ctx.fill();
                    //填充字
                    ctx.fillStyle="#fff";
                    ctx.fillText(text, (Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - ctx.measureText(text).width / 2, (i % 6) * cellHeight + cellHeight / 2);
                    //填充圆点
                    if (PEARL_CODE.LSG.indexOf(letter)!=-1) {//含有庄对
                        ctx.fillStyle = COLOR.blue;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 - cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    if (PEARL_CODE.FSG.indexOf(letter)!=-1) {//含有闲对
                        ctx.fillStyle = COLOR.red;
                        ctx.beginPath();
                        ctx.arc((Math.ceil((i + 1) / 6) - 1) * cellWidth + cellWidth / 2 + cellWidth / 3.5, (i % 6) * cellHeight + cellHeight / 2 - cellHeight / 3.5, cellWidth / radius / 3.5, 0, 2 * Math.PI);
                        ctx.fill();
                    }
                    //数量统计
                    switch (letter) {
                        case 'A':
                            countArr[0]++; countArr[3]++; break;
                        case 'B':
                            countArr[0]++; break;
                        case 'C':
                            countArr[1]++; countArr[4]++; break;
                        case 'D':
                            countArr[1]++; break;
                        case 'E':
                        case 'F':
                            countArr[2]++; break;
                    }
                }
                this.pearlCount.z=countArr[0];
                this.pearlCount.x=countArr[1];
                this.pearlCount.h=countArr[2];
                this.pearlCount.zd=countArr[3];
                this.pearlCount.xd=countArr[4];
            }else if(type==PEARL_TYPE.result_point){
                ctx.font = 'bold ' + cellWidth * .35 + 'px SimHei';
                //绘制列头
                ctx.fillStyle = COLOR.blue;
                ctx.fillRect(0, 0, cellWidth, cellHeight);
                ctx.fillStyle = COLOR.red;
                ctx.fillRect(0, cellHeight + (0 * cellHeight), cellWidth, cellHeight);
                ctx.fillStyle = "#fff";
                for (let i = 0, arr = [this.$t("common.l"), this.$t("common.fong")]; i < arr.length; i++) {
                    ctx.fillText(arr[i], cellWidth / 2 - ctx.measureText(arr[i]).width / 2, (i * cellHeight) + cellHeight / 2);
                }
                //绘制数据
                ctx.font = 'bold ' + cellWidth * .24 + 'px SimHei';
                let arr=JSON.parse(JSON.stringify(this.pokerArr.slice(-(elemObj.column*(this.pearlModel==1?2:1)-2))));
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr[i].length; j++) {
                        let text=this.getPoint(arr[i][j]);
                        if(this.isPokerWin(arr[i],j)){//赢
                            ctx.fillStyle = j==0?COLOR.blue:COLOR.red;
                            ctx.fillRect(cellWidth*((i<elemObj.column-1)?i+1:(i-(elemObj.column-1))), (((i<elemObj.column-1)?j:(2+j)) * cellHeight), cellWidth, cellHeight);
                            ctx.fillStyle = "#fff";
                        }else{//输或平
                            ctx.fillStyle = j==0?COLOR.blue:COLOR.red;
                            if((j==0&&!this.isPokerWin(arr[i],j+1))||(j==1&&!this.isPokerWin(arr[i],j-1))){
                                ctx.fillStyle =COLOR.green;
                            }
                        }
                        ctx.fillText(text,
                            cellWidth*((i<elemObj.column-1)?i+1:(i-(elemObj.column-1))) + cellWidth / 2 - ctx.measureText(text).width / 2,
                            (((i<elemObj.column-1)?j:(2+j)) * cellHeight) + cellHeight / 2);
                    }
                }
            }
        },
        /**
         * 将数据转换成小路需要的数据
         * @param {Array} pearlArr 路单数据
         * @param {PEARL_TYPE} type 小路的类型
         */
        convertToSmallPearl(pearlArr,type){
            let str="";//根据当前判断条件要生成的路单代号
            let arr=[];
            for (let i = 1 + type; i < pearlArr.length; i++){
                for (let j = 0; j < pearlArr[i].length; j++){
                    if ((i > 1 + type) && j == 0) {//从第3列开始，仅比较一次前两列个数
                        if (pearlArr[i - 1].length == pearlArr[i - (2 + type)].length) {//相同(红)
                            str='A';
                        } else {//不同(蓝)
                            str='B';
                        }
                    } else if ((i > 0 + type) && j > 0 && pearlArr[i][j]) {//从第2列第2行开始，如果有值，则对应上一列同行，
                        if (pearlArr[i - (1 + type)][j]) {//有同行(红)
                            str='A';
                        } else {//无同行(蓝，且本列本行到本列结束皆为红)
                            if (j > 1 && !pearlArr[i - (1 + type)][j - 1]) {//有上一行并且上一行无对应的上一列同行
                                str='A';
                            } else {
                                str='B';
                            }
                        }
                    } else {
                        continue;
                    }
                    if(arr.length>0&&arr[arr.length-1].indexOf(str)!=-1){
                        arr[arr.length-1].push(str);
                    }else{
                        arr.push([str]);
                    }
                }
            }
            return arr;
        },
        /**
         * 设置窗口大小改变
         */
        setResize(){
            this.drawPearl(this.pearlArr);
            this.drawMorePearl(this.pearlMoreType);
        },
        /**
         * 设置闪烁位
         */
        setCoruscate(pearl,fn){
            let arr=[];
            pearl=pearl.substr(0,1);
            if(PEARL_CODE.L.indexOf(pearl) != -1){
                arr.push('z');
                // this.$refs.resultAudioPlayer.currentPlayIndex=(this.roomInfo.game==GAME_ID.bac?4:2);
                // this.$nextTick(()=>{
                //     this.$refs.resultAudioPlayer.play();
                // })
            }else if(PEARL_CODE.F.indexOf(pearl) != -1){
                arr.push('x');
                // this.$refs.resultAudioPlayer.currentPlayIndex=(this.roomInfo.game==GAME_ID.bac?3:1);
                // this.$nextTick(()=>{
                //     this.$refs.resultAudioPlayer.play();
                // })
            }else if(PEARL_CODE.HE.indexOf(pearl) != -1){
                arr.push('h');
                // this.$refs.resultAudioPlayer.currentPlayIndex=0;
                // this.$nextTick(()=>{
                //     this.$refs.resultAudioPlayer.play();
                // })
            }
            PEARL_CODE.LSG.indexOf(pearl) != -1 && arr.push('zd');
            PEARL_CODE.FSG.indexOf(pearl) != -1 && arr.push('xd');
            this.coruscateArr=arr;
            setTimeout(() => {
                this.coruscateArr=[];
                fn&&fn();
            }, 3000);
        },
        /**
         * 设置当前佣金模式
         */
        setRebate(e){
            if(this.roomInfo.iscommission==IS_REBATE.false){//不能免佣
                useLayer({str:this.$t("video.noChangeRebate")});
                e.target.checked=false;
            }else{//可以免佣
                let fn=()=>{
                    if(this.currRebate==REBATE_TYPE.rebate){
                        this.currRebate=REBATE_TYPE.noRebate;
                    }else if(this.currRebate==REBATE_TYPE.noRebate){
                        this.currRebate=REBATE_TYPE.rebate;
                    }
                    this.resetAmount("submiting");
                    //获取各位置已提交额度
                    this.$emit("ws-send",COMMAND.bac_getbetdetail,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
                }
                if(this.getAmountCount("submiting")!=0){//有未提交的额度
                    e.target.checked=!e.target.checked;
                    useLayer({
                        type:LAYER_TYPE.confirm,
                        str:this.$t("video.noChangeRebate"),
                        btn:[this.$t("common.yes"),this.$t("common.no")],
                        yesFn:(i)=>{
                            fn();
                            layer.close(i);
                        }
                    });
                }else{//没有未提交的项
                    fn();
                }
            }
        },
        /**
         * 提交事件
         */
        submitEvent(event){
            switch (event) {
                case "clear"://点击清除
                    this.getAmountCount("submiting")!=0&&this.resetAmount("submiting");
                    break;
                case "cancel"://点击取消
                    if(this.canCancel){
                        useLayer({
                            type:LAYER_TYPE.confirm,
                            str:this.$t("common.isCancel"),
                            btn:[this.$t("common.yes"),this.$t("common.no")],
                            yesFn:(i)=>{
                                layer.close(i);
                                this.$emit("ws-send",COMMAND.all_cancelbet,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,this.userInfo.id,this.userInfo.guid);
                            }
                        })
                    }
                    break;
                case "confirm"://点击确定
                    if(this.canConfirm){
                        this.$emit("ws-send",COMMAND.bac_submitbet,this.roomInfo.game,this.roomInfo.id,this.roomInfo.boot,this.roomInfo.innings,
                            this.getAmountCount("submiting"),this.getAmountCount("submiting")+this.submitedCount,this.currRebate,
                            String(this.submiting.z)+","+String(this.submiting.x)+","+String(this.submiting.h)+","+String(this.submiting.zd)+","+String(this.submiting.xd)+",0,0,0,0",
                            this.userInfo.id,this.$root.ip.cip,getDeviceType(),this.userInfo.guid);
                    }
                    break;
                case "multiple"://点击多台
                    this.isMultiple=!this.isMultiple;//变换模式
                    if(this.tabGame!=GAME_ID.bac){
                        this.roomList=[];
                        if(!this.isMultiple){//非多台，重绘路单
                            this.$nextTick(()=>{
                                this.pearlArr=JSON.parse(JSON.stringify(this.pearlArr));
                            })
                        }
                        this.$emit("ws-send",COMMAND.all_getroomlist, this.isMultiple?this.roomInfo.game:this.tabGame,this.userInfo.id, this.userInfo.guid);
                    }
                    break;
            }
        },
        /**
         * 为位置追加将要提交的额度
         * @param {String} type 位置类型(z、x、h、zd、xd)
         */
        addAmount(type,event){
            let limitred=this.limitred[type].split('-');//获取限红
            let amount=this.amount;
            if(this.countdown<=0){
                useLayer({str:this.$t("room.noStartGame")});
                return;
            }else if(this.userInfo.realCoin<this.getAmountCount("submiting")+amount){//余额不足
                useLayer({str:this.$t("common.unMoney")});
                return;
            }
            if(amount!=-1){//非满额度
                if(amount>limitred[1]){//单次高于上限
                    useLayer({str:this.$t("room.higherLimitred")+limitred[1]});
                    return;
                }else if(this.submiting[type]+this.submited[type]+amount>limitred[1]){//多次并高于上限
                    useLayer({str:this.$t("room.laveLimitred")+(limitred[1]-this.submiting[type]-this.submited[type])});
                    return;
                }
            }else{//满额度
                amount=limitred[1]-this.submiting[type]-this.submited[type];
                if(amount==0){
                    useLayer({str:this.$t("room.fullAmount")});
                    return;
                }
            }
            this.submiting[type]=this.submiting[type]+amount;
            if(event){
                this.isScaleAmount[type]=true;
                setTimeout(() => {
                    this.isScaleAmount[type]=false;
                }, 120);
            }
        },
        /**
         * 获取额度
         * @param {String} type 额度类型(submited、submiting、all)
         */
        getAmountCount(type){
            let count=0;
            if(type=="submiting"||type=="all"){//预投或总投
                for (let i in this.submiting) {
                    count+=this.submiting[i];
                }
            }
            if(type=="submited"||type=="all"){//已投或总投
                for (let i in this.submited) {
                    count+=this.submited[i];
                }
            }
            return count;
        },
        /**
         * 重置额度
         */
        resetAmount(type){
            if(type=="submiting"||type=="all"){//预投或总投
                for (let i in this.submiting) {
                    this.submiting[i]=0;
                }
            }
            if(type=="submited"||type=="all"){//已投或总投
                for (let i in this.submited) {
                    this.submited[i]=0;
                }
            }
        },
        /**
         * 问路
         */
        nextPrediction(pearl){
            if(!this.isShowNextPrediction){//当前没有在显示问路，防止多次点击
                this.isShowNextPrediction=true;
                let virtualArr=JSON.parse(JSON.stringify(this.pearlArr));//深克隆一个虚拟数组
                this.updatePearl(virtualArr,pearl);
                this.drawPearl(virtualArr);
                this.nextPredictionTimer = setTimeout(() => {
                    this.drawPearl(this.pearlArr);
                    this.isShowNextPrediction=false;
                }, 1000);
            }
        },
        /**
		 * 更新路单信息
		 * @param {Array} pearlArr 路单列表
		 * @param {String} pearl 路单数据
		 */
		updatePearl(pearlArr,pearl){
			if (pearlArr && pearlArr.length > 0 && (
				(PEARL_CODE.Z.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.Z.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1) ||
				(PEARL_CODE.X.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.X.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1) ||
				(PEARL_CODE.H.indexOf(pearl.substr(0, 1)) != -1 && PEARL_CODE.H.indexOf(pearlArr[pearlArr.length - 1][0].substr(0, 1)) != -1)
				)) {//如果当前的露珠类型与上一项同类，则加到上一项的队列中
				pearlArr[pearlArr.length - 1].push(pearl);
			} else {//否则新建自己的队列
				pearlArr.push([pearl]);
			}
		},
        /**
         * 绘制路单
         */
        drawPearl(pearlArr){
            if(!this.isMultiple){//非多台
                //画表格
                let elemList = document.querySelectorAll("[data-canvas-room]");
                for (let elem of elemList){
                    let val=elem.dataset.canvasRoom;//获取data-canvas-room属性的值
                    //设置画布大小
                    this.$root.setCanvasSize(elem);
                    //绘制画布表格
                    let obj = this.$root.drawCanvasTable(elem,val,false,elem.id=="pointPearl"?2:1);
                    if(obj){//判断对象是否存在，防止视口被拉小到没有的时候找不到而报错
                        if(obj.elem.id=='textBigPearl'){
                            //设置移入带字大路区的遮罩层大小
                            this.pearlLayerStyle.width=obj.cellWidth/RATIO+'px';
                            this.pearlLayerStyle.height=obj.cellHeight/RATIO+'px';
                        }
                        //画路单
                        if(pearlArr.length>0){
                            var delTieArr=this.$root.setPearlData(this.tabGame,pearlArr,pearlArr.length,false);//第一次调用setPearlData是为了去掉和，此时并无法确定要保留多少列
                            if(obj.elem.id=="textBigPearl"){
                                this.drawOrderPearl(obj,pearlArr,PEARL_TYPE.big_text);
                            }else if(obj.elem.id=="threePearl"){
                                this.drawOrderPearl(obj,pearlArr,PEARL_TYPE.big_three);
                            }else if(obj.elem.id=="bigPearl"){
                                this.$root.drawPearl(obj,this.$root.setPearlData(this.tabGame,pearlArr,obj.column-1,false),PEARL_TYPE.big_hollow,GAME_ID.longfong);
                            }else if(obj.elem.id=="smallHollowPearl"){
                                this.$root.drawPearl(obj,
                                    this.$root.setPearlData(this.tabGame,this.convertToSmallPearl(delTieArr,PEARL_TYPE.small_hollow),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                    PEARL_TYPE.small_hollow,GAME_ID.bac);
                            }else if(obj.elem.id=="smallSolidPearl"){
                                this.$root.drawPearl(obj,
                                    this.$root.setPearlData(this.tabGame,this.convertToSmallPearl(delTieArr,PEARL_TYPE.small_solid),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                    PEARL_TYPE.small_solid,GAME_ID.bac);
                            }else if(obj.elem.id=="bevelPearl"){
                                this.$root.drawPearl(obj,
                                    this.$root.setPearlData(this.tabGame,this.convertToSmallPearl(delTieArr,PEARL_TYPE.bevel),obj.column*2-1,false),//第二次调用setPearlData是为了保留多少列
                                    PEARL_TYPE.bevel,GAME_ID.bac);
                            }else if(obj.elem.id=="pointPearl"){
                                this.drawOrderPearl(obj,pearlArr,PEARL_TYPE.result_point);
                            }
                        }
                    }
                }
                //更新下局预测
                if(pearlArr.length>0){
                    if(delTieArr&&delTieArr.length>0){//判断去掉和之后的数组长度大于0，防止第一口为和时报错
                        let lastLetter=delTieArr[delTieArr.length-1][0].substring(0,1);//获取最后一列的第一项的首字母
                        if(PEARL_CODE.L.indexOf(lastLetter)!=-1){//判断最后一列是Z类
                            //下局z
                            this.nextZHollowStyle="color:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-2,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.small_hollow
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);//取倒数第二列拼上追加了虚拟项的倒数第一列
                            this.nextZBevelStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-3,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.bevel
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            this.nextZSolidStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-4,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.small_solid
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            //下局x
                            this.nextXHollowStyle="color:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-2).concat([['D']]),
                                        PEARL_TYPE.small_hollow
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);//取倒数第二列拼上追加了虚拟项的倒数第一列
                            this.nextXBevelStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-3).concat([['D']]),
                                        PEARL_TYPE.bevel
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            this.nextXSolidStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-4).concat([['D']]),
                                        PEARL_TYPE.small_solid
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                        }else if(PEARL_CODE.F.indexOf(lastLetter)!=-1){//X类
                            //下局z
                            this.nextZHollowStyle="color:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-2).concat([['D']]),
                                        PEARL_TYPE.small_hollow
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);//取倒数第二列拼上追加了虚拟项的倒数第一列
                            this.nextZBevelStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-3).concat([['D']]),
                                        PEARL_TYPE.bevel
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            this.nextZSolidStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-4).concat([['D']]),
                                        PEARL_TYPE.small_solid
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            //下局x
                            this.nextXHollowStyle="color:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-2,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.small_hollow
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);//取倒数第二列拼上追加了虚拟项的倒数第一列
                            this.nextXBevelStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-3,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.bevel
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                            this.nextXSolidStyle="background:"+
                                (String(this.convertToSmallPearl(
                                        delTieArr.slice(-4,-1).concat([delTieArr.slice(-1).flat().concat(lastLetter)]),
                                        PEARL_TYPE.small_solid
                                    ).flat().slice(-1))=='B'?COLOR.blue:COLOR.red);
                        }
                    }
                }else{
                    this.pearlCount={z:0,x:0,h:0,zd:0,xd:0};//清空统计
                    this.nextZHollowStyle="";
                    this.nextZBevelStyle="";
                    this.nextZSolidStyle="";
                    this.nextXHollowStyle="";
                    this.nextXBevelStyle="";
                    this.nextXSolidStyle="";
                }
            }
        },
        /**
         * 显示牌
         * @param {Number} type 移入或移出
         */
        showPoker(e,type){
            if(type==1){//移入
                let cellWidth=this.pearlLayerStyle.width.replace('px','');
                let cellHeight=this.pearlLayerStyle.height.replace('px','');
                let column=(getComputedStyle(e.target).width.replace('px','')/cellWidth).toFixed();//获取总列数
                let x = Math.ceil(e.offsetX / cellWidth);//列
                let y = Math.ceil(e.offsetY / cellHeight);//行
                let array=this.pearlArr.flat(Infinity);
                let pokerArr=this.pokerArr.slice(-(column - 1) * 6 - array.length % 6);//取倒数的数据
                let poker=pokerArr[(x-1)*6+(y-1)];//获取对应位置的扑克
                
                if((x!=this.point[0]||y!=this.point[1])&&x!=0&&y!=0&&poker&&this.isShowPoker){//坐标点相比于上次有不同并且大于0行0列并且对应位置有扑克数据
                    this.point=[x,y];
                    this.$nextTick(()=>{
                        this.pearlLayerStyle.display='block';
                        this.pearlLayerStyle.left=(x-1)*cellWidth+'px';
                        this.pearlLayerStyle.top=(y-1)*cellHeight+'px';
                        this.showPokerArr=poker;
                    })
                }
            }else{//移出
                this.showPokerArr=[];
                this.pearlLayerStyle.display='none';
                this.point=[0,0];
            }
        },
        /**
         * 根据牌获取点数
         */
        getPoint(pokerArr){
            let str="";
            let jqkCount = pokerArr.filter(v=>v>41).length;
            str = jqkCount==3?this.$t("common.threeKing"):(jqkCount==2?this.$t("common.twoKing"):(jqkCount==1?this.$t("common.oneKing"):this.$t("common.point")));
            let pointCount=pokerArr.reduce((prev,val)=>prev+(Math.ceil(val/4)>9?0:Math.ceil(val/4)),0)%10||0;
            str=str.length==1?pointCount+str:str+(jqkCount==3?'':pointCount);
            return str;
        },
        /**
         * 牌是否赢
         */
        isPokerWin(pokerArr,index){
            let arr = pokerArr.map(v=>v.reduce((prev,val)=>{
                let point=Math.ceil(val/4);//获取点数
                return prev+(point>9?(point==10?0:10):point)//点数为10时算0，大于10时算10，其余按实际点数
            },0));
            if(arr[index]==30&&arr[1-index]!=30){//共30点并且对方非30点
                return true;
            }else{//非30点
                if(arr[1-index]!=30&&(arr[index]%10>arr[1-index]%10||((arr[index]%10==arr[1-index]%10)&&(arr[index]%100/10>arr[1-index]%100/10)))){//对方非30点并且个位数大或者个位数一样但十位数大
                    return true;
                }
            }
        },
        /**
         * 获取台面路单
         * @param {Number} tableId 台面id
         * @param {Number} column 获取的数据列数
         */
        getRoomPearl(gameId,tableId,column){
            this.$emit("ws-send",COMMAND.all_getroompearl, gameId,tableId,this.tabGame==GAME_ID.longfong?200:column,"list", this.userInfo.id, this.userInfo.guid);
        },
        /**
         * 额度换算
         */
        chipsConvert(amount){
            let arr=[];//最终转换成的额度
            let amountArr = AMOUTN_ARR.slice(0,AMOUTN_ARR.length-1);//取除了梭哈之外的额度
            for (let i = amountArr.length-1; i >= 0; i--) {
                if(amount>=amountArr[i]){
                    let num=Math.floor(amount/amountArr[i]);//获取要换算的额度最多能换到多少个当前遍历到的额度
                    for (let j = 0; j < num; j++) {
                        arr.push(amountArr[i]);
                    }
                    amount-=num*amountArr[i];
                }
            }
            return arr;
        },
        /**
         * 显示全部路单
         * @param {PEARL_TYPE} pearlType 台面id
         */
        showMorePearl(pearlType){
            this.pearlMoreType=pearlType;
            this.$nextTick(()=>{
                this.drawMorePearl(pearlType);
                this.isShowPearlMoreLayer=false;
            })
        },
        /**
         * 绘制全部路单
         */
        drawMorePearl(pearlType){
            let obj;
            let elem=document.getElementById("fullPearl");//获取画布元素
            if(elem){
                let style=getComputedStyle(elem);//获取画布样式
                let height=style.height.replace('px','');//获取画布高度
                let width=getComputedStyle(elem.parentElement).width.replace('px','');//获取一版画布的宽度
                let cellHeight=height/6;//单元格高度
                // pearlType==PEARL_TYPE.result_point&&(cellHeight=height/1)
                let dataColumn=0;//获取数据需要的列数
                let canvasColumn=Math.round(width / cellHeight);//获取一版画布可以绘制多少列
                let arr;
                if(pearlType==PEARL_TYPE.big_text){
                    dataColumn=Math.ceil(this.pearlArr.flat(Infinity).length/6);
                }else if(pearlType==PEARL_TYPE.big_hollow||pearlType==PEARL_TYPE.small_hollow||pearlType==PEARL_TYPE.small_solid||pearlType==PEARL_TYPE.bevel){
                    arr=this.$root.setPearlData(this.roomInfo.game,this.pearlArr,Infinity,false);//第一次调用setPearlData是为了去掉和，此时并无法确定要保留多少列
                    pearlType!=PEARL_TYPE.big_hollow&&(arr=this.convertToSmallPearl(arr,pearlType));
                    dataColumn=arr.length;
                }else if(pearlType==PEARL_TYPE.big_three){
                    dataColumn=Math.ceil(this.pearlArr.flat(Infinity).length/3);
                }else if(pearlType==PEARL_TYPE.result_point){
                    dataColumn=this.pearlArr.length;
                }
                if(dataColumn<canvasColumn){//如果数据需要的列数比一版画布的列数少，说明一版够用
                    this.$root.setCanvasSize(elem);//设置画布大小
                    elem.style.left="";
                    obj = this.$root.drawCanvasTable(elem, pearlType==PEARL_TYPE.result_point?4:6,false,pearlType==PEARL_TYPE.result_point?2:1);
                }else{//一版不够展示
                    //加长画布
                    elem.width=dataColumn*cellHeight*RATIO;
                    elem.height=height*RATIO;
                    elem.style.width=dataColumn*cellHeight+"px";
                    // elem.style.height=height+"px";
                    elem.style.left="";
                    elem.style.left=style.left;
                    obj = this.$root.drawCanvasTable(elem,pearlType==PEARL_TYPE.result_point?4:6,false,pearlType==PEARL_TYPE.result_point?2:1);
                }
                if(pearlType==PEARL_TYPE.big_text||pearlType==PEARL_TYPE.big_three){
                    this.drawOrderPearl(obj,this.pearlArr,pearlType);
                }else if(pearlType==PEARL_TYPE.big_hollow){
                    this.$root.drawPearl(obj,arr,PEARL_TYPE.big_hollow,GAME_ID.bac);
                }else if(pearlType==PEARL_TYPE.small_hollow||pearlType==PEARL_TYPE.small_solid||pearlType==PEARL_TYPE.bevel){
                    this.$root.drawPearl(obj,
                        this.$root.setPearlData(this.tabGame,arr,Infinity,false),//第二次调用setPearlData是为了保留多少列
                        pearlType,GAME_ID.bac,true);
                }else if(pearlType==PEARL_TYPE.result_point){
                    this.drawOrderPearl(obj,this.pearlArr,PEARL_TYPE.result_point);
                }
            }
        },
        /**
         * 移动路单
         */
        movePearlMore(type){
            let elem=document.getElementById("fullPearl");//获取画布元素
            let style=getComputedStyle(elem);//获取画布样式
            let left=Number(style.left.replace('px',''));//获取左边距
            let width=Number(getComputedStyle(elem.parentElement).width.replace('px',''));//获取一版画布的宽度
            let fullWidth=Number(style.width.replace('px',''));//获取整个画布宽度
            type==-1&&(elem.style.left=(Math.abs(left)<=width?0:left+width)+"px");//左边剩下的距离只有不够一版画布的宽度则全部显示，大于一版则只显示一版
            //右边剩下的距离(即画布总宽度-左边剩下的距离-一版画布的宽度)只有不够一版的宽度，则左边距设为总宽度-一版宽度,否则说明剩余超出一版宽度则左边距设为左边距-一版宽度
            type==1&&(elem.style.left=(fullWidth-Math.abs(left)-width<=width?-(fullWidth-width):left-width)+"px");
        },
        /**
         * 自动额度选择
         */
        autoAmount(){
            if(this.currAmountList.length==0){//没有选择过额度
                let max=this.limitred.z.split('-')[1];
                let arr=[];
                if (max<=1000) {
                    arr= [[10,20],[50,100],[200]];
                }else if ((max>1000&&max<=2000)||(max>2000&&max<=5000)) {
                    arr= [[10,50],[100,500],[1000]];
                }else if(max>5000&&max<=10000){
                    arr= [[10,100],[500,1000],[2000]];
                }else if(max>10000&&max<=20000){
                    arr= [[10,100],[500,1000],[5000]];
                }else if(max>20000&&max<=100000){
                    arr= [[10,100],[1000,5000],[10000]];
                }else {
                    arr= [[10,50],[100,500],[1000]];
                }
                this.$store.commit({
                    type:MUTATION_TYPE.setCurrAmountList,
                    currAmountList:arr
                })
            }
        },
        videoReady(videoId){
            this.readyVideoIdArr.push(videoId);
        }
    },
    watch: {
        /**
         * 监听路单数据变化
         */
        pearlArr:{
            handler(newVal){
                this.drawPearl(newVal);
                this.drawMorePearl(this.pearlMoreType);
            },
            deep:true//深度监听
        },
        /**
         * 监听视频线路id改变
         */
        currVideoLine(newVal){
            if(newVal!=""){
                //获取台面视频
                newVal&&this.$emit("ws-send",COMMAND.all_getvideo,this.roomInfo.id,newVal,this.userInfo.id,this.userInfo.guid);
            }else{
                this.videoList=null;
            }
        },
        /**
         * 监听将要提交的额度变化
         */
        submiting:{
            handler(){
                if(this.getAmountCount("submiting")<=0){
                    this.canClear=false;
                    this.canConfirm=false;
                }else{
                    this.canClear=true;
                    this.canConfirm=true;
                }
            },
            deep:true
        },
        /**
         * 监听已提交过的额度变化
         */
        submited:{
            handler(){
                if(this.getAmountCount("submited")<=0){
                    this.canCancel=false;
                }else{
                    this.canCancel=true;
                }
            },
            deep:true
        },
        /**
         * 监听路单模式改变
         */
        pearlModel(){
            this.$nextTick(()=>{
                this.drawPearl(this.pearlArr);
                this.drawMorePearl(this.pearlMoreType);
            })
        },
        /**
         * 监听guid改变（断线重连）
         */
        "userInfo.guid"(newVal,oldVal){
            if(newVal!=oldVal&&newVal!=""){
                this.init();
            }
        },
        readyVideoIdArr(val){
            IS_DEBUG&&console.log(val+"------------------------------------");
        }
    },
};
</script>