<style scoped>
    .bg{
        background: url(@/assets/images/room/texasauto/texasauto_bg_vertical.png) center top/65% 85% no-repeat;
    }
    .poker-shadow{
        box-shadow: -0.05rem 0.1rem #b3b3b3, -0.1rem 0.15rem #9d9d9d, -0.1rem 0.2rem #181818;
    }
    .poker-size{
        width: 1.593rem;
        height: 2.078rem;
    }
    .border-rotate::before{
        content: '';
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -1.8px;
        border-radius: .25rem;
        animation: border-rotate 2s linear infinite;
        z-index: -1;
    }
    @keyframes border-rotate {
        0%{
            background: linear-gradient(0deg,var(--bs-primary),transparent);
        }
        10%{
            background: linear-gradient(36deg,var(--bs-primary),transparent);
        }
        20%{
            background: linear-gradient(72deg,var(--bs-primary),transparent);
        }
        30%{
            background: linear-gradient(108deg,var(--bs-primary),transparent);
        }
        40%{
            background: linear-gradient(144deg,var(--bs-primary),transparent);
        }
        50%{
            background: linear-gradient(180deg,var(--bs-primary),transparent);
        }
        60%{
            background: linear-gradient(216deg,var(--bs-primary),transparent);
        }
        70%{
            background: linear-gradient(252deg,var(--bs-primary),transparent);
        }
        80%{
            background: linear-gradient(288deg,var(--bs-primary),transparent);
        }
        90%{
            background: linear-gradient(324deg,var(--bs-primary),transparent);
        }
        100%{
            background: linear-gradient(360deg,var(--bs-primary),transparent);
        }
    }
    .poker-bg.layer::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        background: rgba(var(--bs-dark-rgb),.5);
        border-radius: .25rem;
    }
    .font-led{animation: big-show 1s infinite;}
    .fade-in-dseat{animation: fade-in .4s backwards;}
    .fade-in-sseat{animation: fade-in .4s .4s backwards;}
    .fade-in-bseat{animation: fade-in .4s .8s backwards;}
    .give-seat{
        top:16%;
        left: 50%;
    }
    
    .give-seat0-poker1{animation: give-seat0-poker1 .2s ease-out forwards;}
    @keyframes give-seat0-poker1 {
        to{
            rotate: 45deg;
            top:87%;
            left:50%;
        }
    }
    .give-seat0-poker2{animation: give-seat0-poker2 .2s ease-out forwards;}
    @keyframes give-seat0-poker2 {
        to{
            rotate: -80deg;
            top:84%;
            left:50%;
        }
    }
    .give-seat1-poker1{animation: give-seat1-poker1 .2s ease-out forwards;}
    @keyframes give-seat1-poker1 {
        to{
            rotate: 45deg;
            top:78%;
            left:30%;
        }
    }
    .give-seat1-poker2{animation: give-seat1-poker2 .2s ease-out forwards;}
    @keyframes give-seat1-poker2 {
        to{
            rotate: -30deg;
            top:78%;
            left:30%;
        }
    }
    .give-seat2-poker1{animation: give-seat2-poker1 .2s ease-out forwards;}
    @keyframes give-seat2-poker1 {
        to{
            rotate: 45deg;
            top:53%;
            left:26%;
        }
    }
    .give-seat2-poker2{animation: give-seat2-poker2 .2s ease-out forwards;}
    @keyframes give-seat2-poker2 {
        to{
            rotate: 15deg;
            top:53%;
            left:31%;
        }
    }
    .give-seat3-poker1{animation: give-seat3-poker1 .2s ease-out forwards;}
    @keyframes give-seat3-poker1 {
        to{
            top:28%;
            left:30%;
        }
    }
    .give-seat3-poker2{animation: give-seat3-poker2 .2s ease-out forwards;}
    @keyframes give-seat3-poker2 {
        to{
            rotate: 30deg;
            top:28%;
            left:30%;
        }
    }
    .give-seat4-poker1{animation: give-seat4-poker1 .2s ease-out forwards;}
    @keyframes give-seat4-poker1 {
        to{
            rotate: -45deg;
            top:26%;
            left:70%;
        }
    }
    .give-seat4-poker2{animation: give-seat4-poker2 .2s ease-out forwards;}
    @keyframes give-seat4-poker2 {
        to{
            rotate: -180deg;
            top:20%;
            left:63%;
        }
    }
    .give-seat5-poker1{animation: give-seat5-poker1 .2s ease-out forwards;}
    @keyframes give-seat5-poker1 {
        to{
            rotate: -45deg;
            top:52%;
            left: 72%; 
        }
    }
    .give-seat5-poker2{animation: give-seat5-poker2 .2s ease-out forwards;}
    @keyframes give-seat5-poker2 {
        to{
            rotate: -70deg;
            top:47%;
            left:71%;
        }
    }
    .give-seat6-poker1{animation: give-seat6-poker1 .2s ease-out forwards;}
    @keyframes give-seat6-poker1 {
        to{
            rotate: -90deg;
            top:72%;
            left:70%;
        }
    }
    .give-seat6-poker2{animation: give-seat6-poker2 .2s ease-out forwards;}
    @keyframes give-seat6-poker2 {
        to{
            rotate: -30deg;
            top:78%;
            left:72%;
        }
    }
    @keyframes big-show {
        0%{
            font-size: 8rem;
        }
        80%{
            font-size: 2rem;
            opacity: 1;
        }
        100%{
            opacity: 0;
        }
    }
    @keyframes fade-in {
        0%{
            opacity: 0;
        }
        100%{
            opacity: 1;
        }
    }
    .chip{
        height: 100%;
        opacity: 0;
    }
    .chip-move{
        opacity: 1;
        left:50%;
        transform: translateX(-50%);
        animation: chip-move .2s ease-out forwards;
    }
    @keyframes chip-move {
        to{
            left: 110%;
            height: 50%;
            transform: translateX(0%);
        }
    }
    .inset--25rem{
        inset:-.25rem
    }
    .bg-primary-gradient{
        background: linear-gradient(90deg,transparent,rgba(var(--bs-primary-rgb),var(--bs-bg-opacity)),transparent);
    }
    .bg-dark-gradient{
        background: linear-gradient(90deg,transparent,rgba(var(--bs-dark-rgb),var(--bs-bg-opacity)) 15%,rgba(var(--bs-dark-rgb),var(--bs-bg-opacity)) 85%,transparent);
    }
    .give-pub-poker1{
        animation:  give-pub-poker1 .2s ease-out forwards,show-poker-rotate .2s .2s ease-out forwards;
    }
    .give-pub-poker1>div,.give-pub-poker2>div,.give-pub-poker3>div,.give-pub-poker4>div,.give-pub-poker5>div{
        animation: show-poker-opacity .1s .2s linear forwards;
    }
    @keyframes give-pub-poker1 {
        to{
            top:40%;
            left:42%;
        }
    }
    .give-pub-poker2{
        animation: give-pub-poker2 .2s ease-out forwards,show-poker-rotate .2s .2s ease-out forwards;
    }
    @keyframes give-pub-poker2 {
        to{
            top:40%;
            left:46%;
        }
    }
    .give-pub-poker3{
        animation: give-pub-poker3 .2s ease-out forwards,show-poker-rotate .2s .2s ease-out forwards;
    }
    @keyframes give-pub-poker3 {
        to{
            top:40%;
            left:50%;
        }
    }
    .give-pub-poker4{
        animation: give-pub-poker4 .2s ease-out forwards,show-poker-rotate .2s .2s ease-out forwards;
    }
    @keyframes give-pub-poker4 {
        to{
            top:40%;
            left:54%;
        }
    }
    .give-pub-poker5{
        animation: give-pub-poker5 .2s ease-out forwards,show-poker-rotate .2s .2s ease-out forwards;
    }
    @keyframes give-pub-poker5 {
        to{
            top:40%;
            left:58%;
        }
    }
    .ms--_1rem{
        margin-left: -.1rem;
    }
    @media (min-width: 576px){
        .bg{
            background: url(@/assets/images/room/texasauto/texasauto_bg.png) center top/65% 80% no-repeat;
        }
        .poker-size{
            width: 2.093rem;
            height: 2.578rem;
        }
        .give-seat{
            top:26%;
            left: 50%;
        }
        @keyframes give-seat0-poker1 {
            to{
                rotate: 45deg;
                top:70%;
                left:50%;
            }
        }
        @keyframes give-seat0-poker2 {
            to{
                rotate: -80deg;
                top:67%;
                left:50%;
            }
        }
        @keyframes give-seat1-poker1 {
            to{
                rotate: 45deg;
                top:70%;
                left:25%;
            }
        }
        @keyframes give-seat1-poker2 {
            to{
                rotate: -30deg;
                top:70%;
                left:25%;
            }
        }
        @keyframes give-seat2-poker1 {
            to{
                rotate: 45deg;
                top:50%;
                left:15%;
            }
        }
        @keyframes give-seat2-poker2 {
            to{
                rotate: 15deg;
                top:50%;
                left:18%;
            }
        }
        @keyframes give-seat3-poker1 {
            to{
                top:28%;
                left:25%;
            }
        }
        @keyframes give-seat3-poker2 {
            to{
                rotate: 30deg;
                top:28%;
                left:25%;
            }
        }
        @keyframes give-seat4-poker1 {
            to{
                rotate: -45deg;
                top:26%;
                left:75%;
            }
        }
        @keyframes give-seat4-poker2 {
            to{
                rotate: -180deg;
                top:20%;
                left:68%;
            }
        }
        @keyframes give-seat5-poker1 {
            to{
                rotate: -45deg;
                top:48%;
                left: 84%; 
            }
        }
        @keyframes give-seat5-poker2 {
            to{
                rotate: -70deg;
                top:40%;
                left:83%;
            }
        }
        @keyframes give-seat6-poker1 {
            to{
                rotate: -90deg;
                top:60%;
                left:75%;
            }
        }
        @keyframes give-seat6-poker2 {
            to{
                rotate: -30deg;
                top:70%;
                left:75%;
            }
        }
    }
</style>
<template>
    <div class="d-flex flex-column h-100">
        <!-- 横向导航 -->
        <navbar class="order-first d-flex flex-column" :has-chat-msg="hasChatMsg" :is-fullscreen="isFullscreen" ></navbar>
        <!-- 内容 -->
        <div class="d-flex flex-column justify-content-end flex-grow-1 bg position-relative mt-_2_5p">
            <div class="d-flex flex-column justify-content-between position-absolute translate-middle-x start-50 top-7 w-95 w-sm-80 h-78 transform-3d perspective-600">
                <!-- 上 -->
                <div class="d-flex flex-fill position-relative">
    
                    <div class="flex-fill d-flex justify-content-start justify-content-sm-center align-items-center align-items-sm-start">
                        <div v-if="getPlayerOfMy(3)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(3)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(3)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(3)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(3,i+1),getStateOfMy(3)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(3).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(3).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(3)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(3)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(3).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(3).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(3)" :class="[getRoleOfMy(3).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(3).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(3).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="flex-fill d-flex justify-content-end justify-content-sm-center align-items-center align-items-sm-start">
                        <div v-if="getPlayerOfMy(4)" class="rounded-3 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(4)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(4)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(4)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(4,i+1),getStateOfMy(4)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(4).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(4).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(4)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(4)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(4).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(4).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(4)" :class="[getRoleOfMy(4).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(4).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(4).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                <!-- 中 -->
                <div class="d-flex flex-fill position-relative">
    
                    <div class="flex-fill d-flex align-items-center">
                        <div v-if="getPlayerOfMy(2)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(2)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(2)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(2)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(2,i+1),getStateOfMy(2)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(2).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(2).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(2)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(2)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(2).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(2).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(2)" :class="[getRoleOfMy(2).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(2).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(2).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="flex-fill d-flex align-items-center justify-content-end">
                        <div v-if="getPlayerOfMy(5)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(5)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(5)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(5)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(5,i+1),getStateOfMy(5)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(5).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(5).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(5)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(5)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(5).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(5).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(5)" :class="[getRoleOfMy(5).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(5).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(5).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                <!-- 下 -->
                <div class="d-flex flex-fill position-relative justify-content-between justify-content-sm-evenly">
    
                    <div class="d-flex justify-content-sm-center align-items-center align-items-sm-end">
                        <div v-if="getPlayerOfMy(1)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(1)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(1)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(1)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(1,i+1),getStateOfMy(1)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(1).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(1).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(1)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(1)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(1).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(1).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(1)" :class="[getRoleOfMy(1).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(1).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(1).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="d-flex justify-content-center align-items-end">
                        <div v-if="getPlayerOfMy(0)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem translate-50-y-xs">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(0)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(0)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(0)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(0,i+1),getStateOfMy(0)==playerStateType.giveup.id?'layer':'',getCardType(getCardOfMy(0).concat(gameInfo.pubcard)).card.indexOf(item)!=-1?'border-rotate':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(0).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(0)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(0)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-100 start-110">
                                    <div v-if="getChipOfMy(0).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(0).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(0)" :class="[getRoleOfMy(0).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(0).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(0).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="d-flex justify-content-end justify-content-sm-center align-items-center align-items-sm-end">
                        <div v-if="getPlayerOfMy(6)" class="rounded-3 bg-dark bg-opacity-50 w-4_6rem position-absolute h-0 pt-6_2rem">
                            <!-- 倒计时 -->
                            <div class="position-absolute rounded-3 inset--25rem" :style="getCountdownStyleOfMy(6)"></div>
                            <div class="d-flex flex-column justify-content-between align-items-center position-absolute w-100 h-100 top-0 small rounded bg-dark">
                                <!-- 名 -->
                                <b class="text-light small">{{getPlayerOfMy(6)['userid']}}</b>
                                <!-- 牌 -->
                                <div class="flex-grow-1 position-relative w-100">
                                    <div v-for="(item,i) of getCardOfMy(6)" :key="item" :class="[i==1?'end-0 me-1':'',getHandCardClassOfMy(6,i+1),getStateOfMy(6)==playerStateType.giveup.id?'layer':'',item?'show':'']" class="poker-bg ms-1 rounded position-absolute top-0">
                                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                                            <span class="fw-bold lh-1 small" :class="{'text-spacing--_1rem ms--_1rem':String($root.getPokerByNum(item)['name']).length>1}">{{ $root.getPokerByNum(item)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 牌型 -->
                                    <div v-show="getCardOfMy(6).every(v=>v)" class="position-absolute bottom-0 bg-dark-gradient bg-opacity-50 w-100 text-center text-light fw-bold small">{{ getCardType(getCardOfMy(6).concat(gameInfo.pubcard)).type }}</div>
                                </div>
                                <!-- 状态 -->
                                <b :class="[getPlayerStateClassOfMy(6)]" class="small">{{Object.values(playerStateType).find(v=>v.id==getPlayerOfMy(6)['state']).str}}</b>
                                <!-- 身份 -->
                                <div class="position-absolute d-flex flex-column justify-content-end h-sm-100 start-sm-110 bottom-0 bottom-sm-auto translate-100-y-xs pt-2 pt-sm-0">
                                    <div v-if="getChipOfMy(6).chip>0" class="h-50 d-flex align-items-end border-bottom border-2 fw-bold text-light pb-1">
                                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                        <span class="lh-sm">{{ getChipOfMy(6).chip }}</span>
                                    </div>
                                    <div class="h-50 d-flex flex-column align-items-start pt-1 text-nowrap">
                                        <span v-if="getRoleOfMy(6)" :class="[getRoleOfMy(6).class]" class="bg-dark rounded-pill fw-bold text-light py-1 px-2">{{ getRoleOfMy(6).name }}</span>
                                    </div>
                                </div>
                                <!-- 筹码 -->
                                <div :class="[getChipOfMy(6).class]" class="position-absolute d-flex align-items-end pb-1 border-2 border-transparent border-start-0 chip">
                                    <img class="w-1_2rem" src="@/assets/images/room/texasauto/chips.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
                <!-- 桌面logo -->
                <div class="position-absolute top-50 start-50 translate-middle">
                    <i class="text-light fw-bold fs-1 text-opacity-50">电子德州</i>
                </div>
                <!-- 桌面牌 -->
                <div class="position-absolute w-100 h-100">
                    <!-- 桌面私牌 -->
                    <div class="position-absolute start-50 translate-middle top-16 top-sm-26">
                        <div class="d-flex flex-column rounded poker-size poker-bg poker-shadow"></div>
                    </div>
                    <div v-if="getPlayerOfMy(3)&&getPlayerOfMy(3)['state']!=playerStateType.wait.id&&getPlayerOfMy(3)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(3,1),getStateOfMy(3)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(3,2),getStateOfMy(3)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(2)&&getPlayerOfMy(2)['state']!=playerStateType.wait.id&&getPlayerOfMy(2)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(2,1),getStateOfMy(2)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(2,2),getStateOfMy(2)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(1)&&getPlayerOfMy(1)['state']!=playerStateType.wait.id&&getPlayerOfMy(1)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(1,1),getStateOfMy(1)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(1,2),getStateOfMy(1)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(0)&&getPlayerOfMy(0)['state']!=playerStateType.wait.id&&getPlayerOfMy(0)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(0,1),getStateOfMy(0)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(0,2),getStateOfMy(0)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(6)&&getPlayerOfMy(6)['state']!=playerStateType.wait.id&&getPlayerOfMy(6)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(6,1),getStateOfMy(6)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(6,2),getStateOfMy(6)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(5)&&getPlayerOfMy(5)['state']!=playerStateType.wait.id&&getPlayerOfMy(5)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(5,1),getStateOfMy(5)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(5,2),getStateOfMy(5)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <div v-if="getPlayerOfMy(4)&&getPlayerOfMy(4)['state']!=playerStateType.wait.id&&getPlayerOfMy(4)['state']!=playerStateType.ready.id">
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(4,1),getStateOfMy(4)==playerStateType.giveup.id?'layer':'']"></div>
                        <div class="poker-size poker-bg position-absolute translate-middle give-seat" :class="[getTableCardClassOfMy(4,2),getStateOfMy(4)==playerStateType.giveup.id?'layer':'']"></div>
                    </div>
                    <!-- 桌面公牌 -->
                    <div v-for="(item,i) of gameInfo.pubcard" :key="item" :class="[getPubCardClass(i),getCardType(getCardOfMy(0).concat(gameInfo.pubcard)).card.indexOf(item)!=-1?'border-rotate':'']" class="position-absolute poker-bg give-seat rounded zoom-_7 init-translate-middle">
                        <div :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border">
                            <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(item)["name"] }}</span>
                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0 zoom-_8"></i>
                                <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                            </div>
                        </div>
                    </div>
                    <!-- 池 -->
                    <div class="position-absolute start-50 top-50 translate-middle-x small">
                        <div class="d-flex fw-bold">
                            <div v-for="(item,i) of poolArr" :key="item" class="d-flex flex-column border border-2 rounded-5 px-2 pb-1 h-3_5rem w-6rem mx-1">
                                <span :class="[i==0?'text-yellow':'text-light']">{{ i==0?'主池':'边池'+(i+1) }}</span>
                                <div class="flex-grow-1 d-flex justify-content-center align-items-center">
                                    <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                                    <span class="lh-sm">{{ item }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 提示 -->
                <div class="position-absolute top-40 start-50 translate-middle">
                    <div v-show="startCountdown>0" class="fw-bold align-items-center position-relative">已有3人准备，游戏即将开始： 
                        <span class="font-led text-danger position-absolute top-50 translate-middle start-xs-50 mt-5 mt-sm-0 ms-sm-3">{{ startCountdown }}</span>
                    </div>
                    <div v-show="gameInfo.state==gameStateType.wait.id&&startCountdown<=0&&gameInfo.pubcard.length==0" class="fw-bold align-items-center position-relative">最少三人准备可开始游戏</div>
                </div>
            </div>
            <!-- 额度信息 -->
            <div class="small text-light d-flex justify-content-center">
                <div class="d-flex mx-3">
                    <span>剩余带入金：</span>
                    <div class="d-flex align-items-center">
                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                        <span>{{ getCurrCarry() }}</span>
                    </div>
                </div>
                <div class="d-flex mx-3">
                    <span>本局已下：</span>
                    <div class="d-flex align-items-center">
                        <img class="w-1_2rem me-1" src="@/assets/images/room/texasauto/chips.png" alt="">
                        <span>{{ roomInfo.carry-getCurrCarry() }}</span>
                    </div>
                </div>
            </div>
            <!-- 操作 -->
            <div class="d-flex justify-content-center bg-dark bg-opacity-50 py-2 text-nowrap">
                <button class="btn btn-success mx-1 px-3 bg-gradient" :disabled="isBtnDisabled(eventType.ready)" @click="btnEvent(eventType.ready)" type="button">{{ (player&&player.state==playerStateType.wait.id)?'准备':'取消准备' }}</button>
                <button class="btn btn-info mx-1 px-3 bg-gradient text-light" :disabled="isBtnDisabled(eventType.pass)" @click="btnEvent(eventType.pass)" type="button">过牌</button>
                <button class="btn btn-primary mx-1 px-3 bg-gradient" :disabled="isBtnDisabled(eventType.follow)" @click="btnEvent(eventType.follow)" type="button">跟注</button>
                <div class="position-relative text-nowrap">
                    <button class="btn btn-warning mx-1 px-3 bg-gradient text-light" :disabled="isBtnDisabled(eventType.append)" @click="isShowCollapseAppend=!isShowCollapseAppend" type="button">
                    {{ Object.values(playerObj).filter(v=>JSON.stringify(v)!="{}"&&v.state!=playerStateType.wait.id&&v.state!=playerStateType.ready.id).every(p=>p.currbet==0)?'下注':'加注' }}
                    </button>
                    <div v-if="isShowCollapseAppend" class="position-absolute bg-warning rounded-3 px-2 py-1 bottom-100 mb-2 d-flex translate-middle-x start-50 w-15rem">
                        <div class="me-2 d-flex flex-column flex-grow-1">
                            <input type="range" class="form-range" :min="minAppend" :max="userInfo.esportsCoin" v-model="rangeAppend">
                            <div class="col-auto">
                                <input type="number" class="form-control form-control-sm" v-model="rangeAppend">
                            </div>
                        </div>
                        <div class="d-flex flex-column justify-content-between">
                            <button class="btn btn-success btn-sm lh-1" type="button" @click="btnEvent(eventType.append)" :disabled="isBtnDisabled(eventType.append)">确定</button>
                            <button class="btn btn-secondary btn-sm lh-1" type="button" @click="isShowCollapseAppend=false">取消</button>
                        </div>
                    </div>
                </div>
                <button class="btn btn-danger mx-1 px-3 bg-gradient text-light" :disabled="isBtnDisabled(eventType.allin)" @click="btnEvent(eventType.allin)" type="button">All in</button>
                <button class="btn btn-secondary mx-1 px-3 bg-gradient" :disabled="isBtnDisabled(eventType.giveup)" @click="btnEvent(eventType.giveup)" type="button">弃牌</button>
            </div>
            <!-- 结算 -->
            <div v-if="isShowScore" class="position-absolute bg-dark bg-opacity-50 w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                <div class="bg-primary-gradient w-80 d-flex justify-content-center align-items-center">
                    <div class="d-flex flex-column text-nowrap text-light fw-bold px-5 py-3">
                        <div class="d-flex justify-content-center mb-2 pb-2 border-bottom zoom-_7">
                            <div v-for="(item,i) of gameInfo.pubcard" :key="i" :class="[$root.getPokerByNum(item)['color']]" class="d-flex flex-column bg-light rounded p-1 border mx-1 small">
                                <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(item)["name"] }}</span>
                                <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                    <i :class="[$root.getPokerByNum(item)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                    <i :class="[$root.getPokerByNum(item)['shape']]" class="bi fs-4"></i>
                                </div>
                            </div>
                        </div>
                        <table class="mx-5">
                            <tr>
                                <td class="px-4 pb-1">玩家</td>
                                <td class="px-4 pb-1 text-center">手牌</td>
                                <td class="px-4 pb-1 text-center">最佳牌型</td>
                                <td class="px-4 pb-1">竞技币</td>
                            </tr>
                            <tr v-for="(item,i) of playerScoreArr" :key="item" :class="{'text-warning bg-primary-gradient bg-opacity-75':i==Object.values(playerObj).findIndex(v=>v.userid==userInfo.id)}">
                                <td class="px-4">{{ item.userid }}</td>
                                <td class="px-4 py-2 small zoom-_7">
                                    <div class="d-flex">
                                        <div v-for="j of item.card" :key="j" :class="[$root.getPokerByNum(j)['color']]" class="d-flex flex-column bg-light rounded p-1 border me-1">
                                            <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(j)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(j)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(j)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="px-4 py-1 small zoom-_7 position-relative">
                                    <div class="d-flex">
                                        <div v-for="i in getCardType(gameInfo.pubcard.concat(item.card)).card" :key="i" :class="[$root.getPokerByNum(i)['color']]" class="d-flex flex-column bg-light rounded p-1 border me-1">
                                            <span class="fw-bold lh-1 small">{{ $root.getPokerByNum(i)["name"] }}</span>
                                            <div class="d-flex flex-grow-1 position-relative px-2 pb-1 small">
                                                <i :class="[$root.getPokerByNum(i)['shape']]" class="d-flex bi small position-absolute start-0"></i>
                                                <i :class="[$root.getPokerByNum(i)['shape']]" class="bi fs-4"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="position-absolute top-50 start-50 translate-middle-x fs-6 bg-dark bg-opacity-75 text-light px-2 rounded-pill">{{getCardType(gameInfo.pubcard.concat(item.card)).type}}</span>
                                </td>
                                <td class="px-4 text-end">{{ item.gain.reduce((prev,val)=>prev+val,0) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="mt-2">
                    <button class="btn btn-success mx-2" @click="btnEvent(eventType.ready)" type="button">继续游戏</button>
                    <button class="btn btn-danger mx-2" @click="isShowScore=!isShowScore" type="button">关闭</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { COMMAND, GAME_ID, LAYER_TYPE,MUTATION_TYPE } from "@/assets/js/config.js";
import navbar from '@/components/public/navbar.vue';
import { useLayer } from '@/assets/js/useLayer';
export default {
    components: { 
        navbar
    },
    props: {
        hasChatMsg:false,//是否有未读的客服消息
        isPageReady:false,//页面是否加载完毕
        isFullscreen:{
            type:Boolean,
            default:false
        },//当前是否全屏
    },
    data() {
        return {
            eventType:{
                unready:0,//取消准备
                ready:1,//准备
                pass:2,//过
                follow:3,//跟
                append:4,//加
                allin:5,//all
                giveup:6,//弃
            },//操作类型
            playerStateType:{
                wait:{
                    id:1,
                    str:"空闲"
                },
                ready:{
                    id:2,
                    str:"已准备"
                },
                playing:{
                    id:3,
                    str:"游戏中"
                },
                current:{
                    id:4,
                    str:"思考中"
                },
                follow:{
                    id:5,
                    str:"跟注"
                },
                append:{
                    id:6,
                    str:"加注"
                },
                allin:{
                    id:7,
                    str:"梭哈"
                },
                pass:{
                    id:8,
                    str:"过牌"
                },
                giveup:{
                    id:9,
                    str:"弃牌"
                }
            },//玩家状态类型
            gameStateType:{
                wait:{
                    id:1,
                    str:"空闲"
                },
                countdown:{
                    id:2,
                    str:"计时中"
                },
                first:{
                    id:3,
                    str:"第一轮"
                },
                second:{
                    id:4,
                    str:"第二轮"
                },
                third:{
                    id:5,
                    str:"第三轮"
                },
                fourth:{
                    id:6,
                    str:"第四轮"
                },
                end:{
                    id:7,
                    str:"结算"
                }
            },//游戏状态类型
            cardType:{
                maxColorOrder: '皇家同花顺',
                colorOrder: '同花顺',
                shijo: '四条',
                gourd: '葫芦',
                color: '同花',
                order: '顺子',
                sanjo: '三条',
                twoPairs: '两对',
                pairs: '对子',
                messy: '散牌'
            },//牌型
            isEvent:false,//是否在处理事件中
            playerObj:{},//台面玩家对象
            player:null,//当前玩家
            gameInfo:{
                state:0,//游戏状态
                dseat:0,
                sseat:0,
                bseat:0,
                countdown:0,
                pubcard:[],
                pricard:[],
                antes:0,
            },//游戏信息
            startCountdown:0,//游戏即将开始前的计时
            startCountDownWorker:null,//游戏即将开始前的webworker对象
            roleClassArr:[],//身份class样式数组
            tableCardClassArr:[],//玩家桌牌class样式数组
            handCardClassArr:[],//玩家手牌class样式数组
            chipClassArr:[],//额度class样式数组
            playerCardArr:[],//所有玩家牌数组
            countdownStyleArr:[],//倒计时样式数组
            isShowScore:false,//是否显示成绩
            pubCardClassArr:[],//公牌class样式数组
            playerScoreArr:[],//玩家成绩数组
            rangeAppend:0,//追加的额度
            isShowCollapseAppend:false,//是否显示追加额度
            minAppend:0,//最小追加额度
            poolArr:[],//池额数组
        };
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
        roomInfo() {
            return this.$store.state.roomInfo;
        },
    },
    mounted() {
        this.startCountDownWorker=new Worker("timeWorker.js"); 
        this.startCountDownWorker.onmessage=(e)=>{
            this.startCountdown=e.data;
        }
        this.$emit('set-before-gopage-fn',()=>{//跳转页面前
            if(this.player.state==this.playerStateType.wait.id||this.player.state==this.playerStateType.ready.id){//游戏未开始则可直接退出
                this.$emit('ws-send',COMMAND.all_exittable,GAME_ID.texasauto,this.roomInfo.id,this.userInfo.id, this.userInfo.guid);
            }else{//游戏进行中需要提示再退出
                useLayer({type:LAYER_TYPE.confirm,str:"游戏中退出将会自动弃牌，是否退出？",btn:["是","否"],yesFn:()=>{
                    this.$root.goPage("home");
                }})
            }
        })
        this.init();
        this.setWsMessage();
    },
    methods: {
        init(){
            this.$emit('ws-send',COMMAND.texa_gettablepro,GAME_ID.texasauto,this.roomInfo.id,this.userInfo.id, this.userInfo.guid);
            this.$emit('ws-send',COMMAND.texa_getpool,GAME_ID.texasauto,this.roomInfo.id,this.userInfo.id, this.userInfo.guid);
        },
        setWsMessage(){
            this.$emit("ws-msg",async (data)=>{
                switch (data.command) {
                    case COMMAND.texa_gettablepro:
                        
                        // if(data.gamestate==1){

                        //     // 测试代码
                        //     data.gamestate=3;
                        //     data.dseat=1;
                        //     data.sseat=2;
                        //     data.bseat=3;
                        //     data.pubcard=[];
                        //     data.pricard=[47,51];
                        //     data.isget=false;
                        //     data.antes=100;
                        //     data.countdown=15;
                        //     data.list={
                        //         "0":{"userid":"a11","state":4,"currbet":200,"boutbet":0},
                        //         "1":{"userid":"a12","state":3,"currbet":100,"boutbet":0},
                        //         "2":{"userid":"a13","state":3,"currbet":100,"boutbet":0},
                        //         "3":{},
                        //         "4":{},
                        //         "5":{},
                        //         "6":{},
                        //         // "7":{"userid":"a18","state":3,"currbet":0,"boutbet":0}
                        //     }
                        // }

                        //计算池
                        let poolArr=[];
                        if(data.gamestate==this.gameStateType.second.id||data.gamestate==this.gameStateType.third.id||data.gamestate==this.gameStateType.fourth.id){
                            poolArr = this.sumPool();
                        }
                        
                        this.gameInfo.state=data.gamestate;
                        this.gameInfo.dseat=data.dseat;
                        this.gameInfo.sseat=data.sseat;
                        this.gameInfo.bseat=data.bseat;
                        this.gameInfo.countdown=data.countdown;
                        this.gameInfo.pubcard=data.pubcard;
                        // this.gameInfo.pricard=data.pricard; 
                        this.gameInfo.antes=data.antes;
                        this.playerObj=data.list;
                        this.player=Object.values(this.playerObj).find(v=>v.userid==this.userInfo.id);
                        // this.playerCardArr[Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id)]=data.pricard;
                        //设置最小追加额度
                        let currIndex=Object.values(this.playerObj).findIndex(v=>v.state==this.playerStateType.current.id&&v.userid==this.userInfo.id);//获取当前行动玩家的下标
                        (currIndex!=-1)&&this.setMinAppend(currIndex);//找到下标说明当前轮到我行动
                        //展示身份
                        for (let i = 0; i < 3; i++) {
                            let index;
                            let role={
                                name:"",
                                class:""
                            }
                            if(i==0&&data.dseat!=0){
                                index=data.dseat;
                                role.name="D";
                                role.class=data.isget?"":"fade-in-dseat";
                            }else if(i==1&&data.sseat!=0){
                                index=data.sseat;
                                role.name="小盲位";
                                role.class=data.isget?"":"fade-in-sseat";
                            }else if(i==2&&data.bseat!=0){
                                index=data.bseat;
                                role.name="大盲位";
                                role.class=data.isget?"":"fade-in-bseat";
                            }
                            this.roleClassArr[index-1]=role;
                        }
                        if(!data.isget){//自动推送下，身份显示需要耗时1200ms
                            await new Promise((resolve)=>{
                                let roleWorker=new Worker("timeWorker.js");
                                roleWorker.onmessage=(e)=>{
                                    (e.data==0)&&resolve();
                                }
                                roleWorker.postMessage({number:1,ms:1200});
                            })
                        }
                        //下额度
                        let arr=Object.values(this.playerObj);
                        for (let i in arr) {
                            if(!isNaN(arr[i].currbet)){
                                if(!data.isget&&arr[i].currbet>0){//自动推送下
                                    await this.setPlayerChip(arr[i].currbet,i);
                                    this.$store.commit({
                                        type:MUTATION_TYPE.setUserInfo,
                                        userInfo:{
                                            esportsCoin:this.userInfo.esportsCoin-arr[i].currbet
                                        }
                                    })
                                }else{
                                    !this.chipClassArr[i]&&(this.chipClassArr[i]={});
                                    this.chipClassArr[i].class="";
                                    this.chipClassArr[i].chip=arr[i].currbet;
                                }
                            }
                        }
                        // 展示池
                        await this.setPool(poolArr);
                        //发私牌
                        if(data.dseat!=0&&data.sseat!=0&&data.bseat!=0){
                            for (let j = 1; j < 3; j++) {//两轮牌
                                for (let i = data.sseat-1; i < 7+(data.sseat-1); i++) {//第一张从小盲开始
                                    if(JSON.stringify(data.list[i%7])!="{}"&&data.list[i%7].state!=this.playerStateType.wait.id&&data.list[i%7].state!=this.playerStateType.ready.id){//处于游戏中的玩家
                                        if(!data.isget&&data.gamestate==this.gameStateType.first.id){//自动推送下并且为第一轮
                                            await new Promise((resolve)=>{
                                                let cardAnimWorker=new Worker("timeWorker.js");
                                                cardAnimWorker.onmessage=(e)=>{
                                                    if(e.data==0){
                                                        //玩家桌牌
                                                        (!this.tableCardClassArr[i%7])&&(this.tableCardClassArr[i%7]={});
                                                        this.tableCardClassArr[i%7][j]="";
                                                        //玩家手牌
                                                        (!this.handCardClassArr[i%7])&&(this.handCardClassArr[i%7]={});
                                                        !this.playerCardArr[i%7]&&(this.playerCardArr[i%7]=[]);
                                                        if(data.list[i%7].userid==this.userInfo.id){//为我添加牌点(一张一张加)
                                                            this.playerCardArr[i%7][j-1]=data.pricard[j-1];
                                                            this.handCardClassArr[i%7][j]="show";
                                                        }else{//其他玩家添加空牌
                                                            this.playerCardArr[i%7][j-1]="";
                                                        }
                                                        resolve();
                                                    }
                                                }
                                                cardAnimWorker.postMessage({number:1,ms:200});//每张耗时200ms
                                            })
                                        }else{
                                            //玩家桌牌
                                            (!this.tableCardClassArr[i%7])&&(this.tableCardClassArr[i%7]={});
                                            this.tableCardClassArr[i%7][j]="animation-duration-0 ";
                                            //玩家手牌
                                            (!this.handCardClassArr[i%7])&&(this.handCardClassArr[i%7]={});
                                            if(data.list[i%7].userid==this.userInfo.id){//为我添加牌点(一次性加)
                                                this.playerCardArr[i%7]=data.pricard;
                                                this.handCardClassArr[i%7][j]="animation-duration-0 show";
                                            }else{//其他玩家添加空牌
                                                this.playerCardArr[i%7]=["",""];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        //发公牌
                        for (let i = 0; i < data.pubcard.length; i++) {
                            if(!data.isget){
                                await this.setPubcard(i);
                            }else{
                                this.pubCardClassArr[i]="give-pub-poker"+(i+1)+" animation-duration-0";
                            }
                        }
                        //为当前轮到的玩家倒计时
                        if(data.isget){
                            this.setPlayerCountdown(this.gameInfo.countdown);
                        }
                        break;
                    case COMMAND.all_readygame:
                        let player = Object.values(this.playerObj).find(v=>v.userid==data.id);//查找当前进出台面的玩家是否已存在当前台面中
                        if(data.id==this.userInfo.id){//当前用户
                            if(data.state==2){
                                useLayer({str:"操作失败！"});
                                return;
                            }
                            data.state==this.eventType.ready&&(this.player.state=this.playerStateType.ready.id);
                            data.state==this.eventType.unready&&(this.player.state=this.playerStateType.wait.id);
                            this.isEvent=false;
                        }else{//其他用户
                            if(player){
                                data.state==this.eventType.ready&&(player.state=this.playerStateType.ready.id);
                                data.state==this.eventType.unready&&(player.state=this.playerStateType.wait.id);
                            }
                        }
                        break;
                    case COMMAND.all_tableupdate:
                        if(data.tableid==this.roomInfo.id&&data.userid!=this.userInfo.id){//台面为当前台面并且为其他玩家
                            let player = Object.values(this.playerObj).find(v=>v.userid==data.userid);//查找当前进出台面的玩家是否已存在当前台面中
                            if(data.mode==1){//进入
                                if(!player){
                                    this.playerObj[data.loc-1].userid=data.userid;
                                    this.playerObj[data.loc-1].state=this.playerStateType.wait.id;
                                    this.playerObj[data.loc-1].currbet=0;
                                    this.playerObj[data.loc-1].boutbet=0;
                                }
                            }else if(data.mode==-1){//退出
                                if(player){
                                    this.playerObj[data.loc-1]={};
                                }
                            }
                        }
                        break;
                    case COMMAND.all_willstartgame:
                        if(this.gameInfo.state==this.gameStateType.wait.id){//在空闲状态接收到才会计时
                            this.startCountdown=data.countdown;
                            if(data.isready==1){//游戏即将开始
                                this.startCountDownWorker.postMessage({number:data.countdown});
                                this.cleanRoom();
                                this.gameInfo.state=this.gameStateType.countdown.id;
                            }else if(data.isready==0){//取消游戏即将开始
                                this.startCountDownWorker.postMessage({number:0});
                                this.gameInfo.state=this.gameStateType.wait.id;
                            }
                        }
                        break;
                    case COMMAND.texa_move:
                        let movePlayer = this.playerObj[data.seat-1];//查找当前行动的玩家是否已存在当前台面中
                        if(movePlayer){
                            movePlayer.state=this.playerStateType.current.id;
                            this.setPlayerCountdown();
                            this.setMinAppend(data.seat-1);
                        }
                        break;
                    case COMMAND.texa_submitbet:
                        if(data.result==0){
                            useLayer({str:"操作失败！"});
                        }else if(data.tableid==this.roomInfo.id&&data.result==1){
                            let subPlayer=Object.values(this.playerObj).find(v=>v.userid==data.userid);//查找当前提交的玩家是否已存在当前台面中
                            if(subPlayer){
                                subPlayer.state=data.type;
                            }
                            if(data.type==this.playerStateType.follow.id||data.type==this.playerStateType.append.id||data.type==this.playerStateType.allin.id){
                                this.playerObj[data.seat-1].currbet=data.bet;
                                this.setPlayerChip(data.bet,data.seat-1);
                                this.$store.commit({
                                    type:MUTATION_TYPE.setUserInfo,
                                    userInfo:{
                                        esportsCoin:this.userInfo.esportsCoin-data.bet
                                    }
                                })
                            }
                            this.setMinAppend(data.seat-1);//设置最小追加额度
                            this.isShowCollapseAppend=false;//隐藏加注面板
                            subPlayer.currbet=data.bet;
                        }
                        data.userid==this.userInfo.id&&(this.isEvent=false);
                        break;
                    case COMMAND.texa_end:
                        let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
                        this.gameInfo.state=this.gameStateType.wait.id;
                        //处理池
                        let sumPoolArr = this.sumPool();
                        Object.values(this.playerObj).forEach((v,i)=>{
                            if(v.currbet){
                                !this.chipClassArr[i]&&(this.chipClassArr[i]={});
                                this.chipClassArr[i].class="";
                                this.chipClassArr[i].chip=0;
                            }
                        });
                        await this.setPool(sumPoolArr);
                        //发公牌
                        this.gameInfo.pubcard=data.pubcard;
                        for (let i = 0; i < data.pubcard.length; i++) {
                            await this.setPubcard(i);
                        }
                        let playerArr=Object.values(data.list).filter(v=>v.card.length>0);//获取有参与游戏的玩家
                        Object.keys(data.list).filter(v=>data.list[v].card.length>0).forEach((v,i)=>playerArr[i].userid=this.playerObj[v].userid);
                        // Object.values(data.list).forEach((v,i)=>v.card.length>0&&(playerArr[i].userid=this.playerObj[i].userid));//数据添加玩家id
                        this.playerScoreArr=playerArr;
                        Object.values(this.playerObj).forEach((v,i)=>v.state&&v.state!=this.playerStateType.giveup&&(this.playerCardArr[i]=data.list[i].card));//展示非弃牌玩家的牌
                        this.poolArr=[];//清除池
                        if(data.list[myIndex].card.length>0){//我有参与游戏才会显示结算
                            setTimeout(() => {
                                this.isShowScore=true;//显示结算界面
                                Object.values(this.playerObj).forEach((v,i)=>v.state&&(v.state=this.playerStateType.wait.id));//修改有参与游戏的玩家的状态
                            }, 2300);
                        }
                        break;
                    case COMMAND.texa_getpool:
                        this.poolArr=data.list;
                        break;
                }
            })
        },
        /**
         * 按钮事件
         */
        btnEvent(event){
            if(!this.isEvent){
                switch (event) {
                    case this.eventType.ready:
                        let index=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);
                        if(this.playerObj[index].state==this.playerStateType.wait.id||this.playerObj[index].state==this.playerStateType.ready.id){//当前玩家状态为空闲或已准备
                            this.isEvent=true;
                            this.cleanRoom();
                            this.$emit('ws-send', COMMAND.all_readygame,GAME_ID.texasauto,this.roomInfo.id,
                            this.playerObj[index].state==this.playerStateType.wait.id?this.eventType.ready:this.eventType.unready,
                                this.userInfo.id, this.userInfo.guid);
                        }
                        break;
                    case this.eventType.pass:
                        if(!this.isBtnDisabled(this.eventType.pass)){
                            this.isEvent=true;
                            this.$emit('ws-send', COMMAND.texa_submitbet,GAME_ID.texasauto,this.roomInfo.id,0,this.playerStateType.pass.id,this.userInfo.id, this.userInfo.guid);
                        }
                        break;
                    case this.eventType.follow:
                        this.isEvent=true;
                        let maxChip = Math.max.apply(null,Object.values(this.playerObj).filter(p=>JSON.stringify(p)!="{}"&&p.state!=this.playerStateType.wait.id&&p.state!=this.playerStateType.ready.id&&p.state!=this.playerStateType.giveup.id).map(v=>v.currbet||0));//获取有效且游戏中玩家最大额度
                        this.$emit('ws-send', COMMAND.texa_submitbet,GAME_ID.texasauto,this.roomInfo.id,maxChip,this.playerStateType.follow.id,this.userInfo.id, this.userInfo.guid);
                        break;
                    case this.eventType.append:
                        this.isEvent=true;
                        this.$emit('ws-send', COMMAND.texa_submitbet,GAME_ID.texasauto,this.roomInfo.id,this.rangeAppend,this.playerStateType.append.id,this.userInfo.id, this.userInfo.guid);
                        break;
                    case this.eventType.allin:
                        this.isEvent=true;
                        this.$emit('ws-send', COMMAND.texa_submitbet,GAME_ID.texasauto,this.roomInfo.id,0,this.playerStateType.allin.id,this.userInfo.id, this.userInfo.guid);
                        break;
                    case this.eventType.giveup:
                        if(!this.isBtnDisabled(this.eventType.giveup)){
                            this.isEvent=true;
                            this.$emit('ws-send', COMMAND.texa_submitbet,GAME_ID.texasauto,this.roomInfo.id,0,this.playerStateType.giveup.id,this.userInfo.id, this.userInfo.guid);
                        }
                        break;
                }
            }
        },
        /**
         * 根据与我的位置间距获取其他位置对象
         * @param {Number} nextN 距离我之后几个位，0则为自己
         */
        getPlayerOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let player=this.playerObj[(myIndex+nextN)%7];
            return JSON.stringify(player)=="{}"?null:player;
        },
        /**
         * 根据与我的位置间距获取角色
         */
        getRoleOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.roleClassArr[index]){
                return this.roleClassArr[index];
            }
            return null;
        },
        /**
         * 根据与我的位置间距获取玩家状态class
         */
        getPlayerStateClassOfMy(nextN){
            let classStr="";
            let state=this.getPlayerOfMy(nextN).state;
            if(state==this.playerStateType.ready.id){
                classStr="text-success";
            }else if(state==this.playerStateType.wait.id){
                classStr="text-light";
            }else if(state==this.playerStateType.giveup.id){
                classStr="text-secondary";
            }else if(state==this.playerStateType.playing.id){
                classStr="text-coppery";
            }else if(state==this.playerStateType.current.id){
                classStr="text-yellow";
            }else if(state==this.playerStateType.pass.id){
                classStr="text-info";
            }else if(state==this.playerStateType.follow.id){
                classStr="text-primary";
            }else if(state==this.playerStateType.allin.id){
                classStr="text-danger";
            }else if(state==this.playerStateType.append.id){
                classStr="text-warning";
            }
            return classStr;
        },
        /**
         * 根据与我的位置间距和第几张牌获取玩家桌牌class
         */
        getTableCardClassOfMy(nextN,pokerNum){
            let classStr="";
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.tableCardClassArr[index]){
                if(typeof this.tableCardClassArr[index][pokerNum]=="string"){//有样式（包括空样式）则追加在前面
                    classStr=this.tableCardClassArr[index][pokerNum]+"give-seat"+nextN+"-poker"+pokerNum;
                }
            }
            return classStr;
        },
        /**
         * 根据与我的位置间距和第几张牌获取玩家手牌class
         */
        getHandCardClassOfMy(nextN,pokerNum){
            let classStr="";
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.handCardClassArr[index]){
                if(this.handCardClassArr[index][pokerNum]){
                    classStr=this.handCardClassArr[index][pokerNum];
                }
            }
            return classStr;
        },
        /**
         * 根据与我的位置间距获取玩家额度class
         */
        getChipOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.chipClassArr[index]){
                return this.chipClassArr[index];
            }
            return {
                class:""
            };
        },
        /**
         * 根据与我的位置间距获取玩家牌
         */
        getCardOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.playerCardArr[index]){
                return this.playerCardArr[index];
            }
            return [];
        },
        /**
         * 根据与我的位置间距获取倒计时style
         */
        getCountdownStyleOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.countdownStyleArr[index]){
                return this.countdownStyleArr[index];
            }
            return "";
        },
        /**
         * 根据与我的位置间距获取状态
         */
        getStateOfMy(nextN){
            let myIndex=Object.values(this.playerObj).findIndex(v=>v.userid==this.userInfo.id);//获取我的下标
            let index=(myIndex+nextN)%7;//获取当前要获取角色的玩家的下标
            if(this.playerObj[index].state){
                return this.playerObj[index].state;
            }
        },
        /**
         * 设置玩家倒计时
         */
        setPlayerCountdown(currCountdown=15){
            let countdownWorker=new Worker("timeWorker.js");
            let countdown=15;//总秒数
            let lastPlayerIndex=Object.values(this.playerObj).findIndex(v=>v.state==this.playerStateType.current.id);//上一次的玩家位置
            this.countdownStyleArr.forEach((v,i,arr)=>arr[i]="");//移除所有倒计时样式
            countdownWorker.onmessage=(e)=>{
                let currPlayerIndex=Object.values(this.playerObj).findIndex(v=>v.state==this.playerStateType.current.id);//当前轮到的玩家位置，每次都要查找一次，使状态改变时能停止掉
                if(currPlayerIndex>-1&&(lastPlayerIndex==-1||currPlayerIndex==lastPlayerIndex)){//当前有轮到玩家并且于上一次的玩家相同
                    let color=e.data>50?"#00d900":(e.data>25?"#d9a200":"#ff0000");
                    this.countdownStyleArr[currPlayerIndex]="background:conic-gradient(#656565 "+(100-e.data)+"%,"+color+" "+(100-e.data)+"%,"+color+" 100%);";
                    lastPlayerIndex=currPlayerIndex;
                }else{//否则需要停掉倒计时
                    countdownWorker.postMessage({ms:0});
                    this.countdownStyleArr.forEach((v,i,arr)=>arr[i]="");//移除所有倒计时样式
                }
            }
            countdownWorker.postMessage({number:currCountdown/countdown*100,ms:countdown*1000/100});//倒计时效果需要走100次，每百分之一总毫秒走一次
        },
        /**
         * 按钮是否不可用
         */
        isBtnDisabled(event){
            let player=Object.values(this.playerObj).find(v=>v.userid==this.userInfo.id);
            if(player){
                let state=player.state;
                let playerArr=Object.values(this.playerObj).filter(v=>JSON.stringify(v)!="{}"&&v.state!=this.playerStateType.wait.id&&v.state!=this.playerStateType.ready.id);//找到正在游戏的玩家
                let currbetArr=playerArr.map(v=>v.currbet);
                if(event==this.eventType.ready){
                    if(state!=this.playerStateType.wait.id&&state!=this.playerStateType.ready.id){//不是准备和空闲状态则不可用
                        return true;
                    }
                }else if(event==this.eventType.follow||event==this.eventType.append||event==this.eventType.allin||event==this.eventType.giveup||event==this.eventType.pass){
                    if(player.state!=this.playerStateType.current.id||this.gameInfo.state==this.playerStateType.wait.id||this.gameInfo.state==this.playerStateType.ready.id){//当前不是轮到自己或者游戏状态处于未开始则不可用
                        return true;
                    }
                    if(event==this.eventType.follow){//所有正在游戏的玩家本轮额度一致或者我的余额不足时不能使用
                        if(Math.max.apply(null,currbetArr)==Math.min.apply(null,currbetArr)){//额度全部一致
                            return true;
                        }
                    }else if(event==this.eventType.pass){//所有正在游戏的玩家的本轮额度不一致时不可用
                        if(Math.max.apply(null,currbetArr)!=Math.min.apply(null,currbetArr)){//额度不一致
                            return true;
                        }
                    }else if(event==this.eventType.append){
                        if(this.getCurrCarry()<=0){//当前剩余带入金不足时不可用
                            return true;
                        }
                    }else if(event==this.eventType.allin){
                        if(this.getCurrCarry()<=0){//当前剩余带入金不足时不可用
                            return true;
                        }
                    }
                }
            }
        },
        /**
         * 设置玩家额度
         */
        setPlayerChip(chip,seat){
            return new Promise(async (res)=>{
                !this.chipClassArr[seat]&&(this.chipClassArr[seat]={});
                this.chipClassArr[seat].class="chip-move";
                //移额度
                await new Promise((resolve)=>{
                    let chipWorker=new Worker("timeWorker.js");
                    chipWorker.onmessage=(e)=>{
                        if(e.data==0){
                            this.chipClassArr[seat].class="";
                            resolve();
                        }
                    }
                    chipWorker.postMessage({number:1,ms:200});
                })
                //额度计数
                await new Promise((resolve)=>{
                    let chipNumWorker=new Worker("timeWorker.js");
                    let count=chip<10?1:10;//根据额度设置计数的次数
                    chipNumWorker.onmessage=(e)=>{
                        let chipNum=chip/count*(count-e.data);
                        this.chipClassArr[seat].chip=e.data==0?chipNum:Math.ceil(chipNum);
                        (e.data==0)&&resolve();
                    }
                    chipNumWorker.postMessage({number:count,ms:500/count});
                })
                res();
            })
        },
        /**
         * 根据下标获取公牌class
         */
        getPubCardClass(index){
            if(this.pubCardClassArr[index]){
                return this.pubCardClassArr[index];
            }
        },
        /**
         * 获取最佳牌型
         */
        getCardType(arr){
            if(
                (this.gameInfo.state!=this.gameStateType.wait.id&&(this.player.state!=this.playerStateType.wait.id&&this.player.state!=this.playerStateType.ready.id))||
                (this.gameInfo.state==this.gameStateType.wait.id&&(this.player.state==this.playerStateType.wait.id||this.player.state==this.playerStateType.ready.id))
            ){//我在玩并且游戏状态不为空闲，或者我没玩并且游戏状态为空闲
                let array=[];
                let reCardArr=arr.map(v=>v==1?53:v==2?54:v==3?55:v==4?56:v).sort((a,b)=>b-a);//牌替换掉A并从大到小排序
                let AArr=reCardArr.filter(v=>[56,55,54,53].indexOf(v)!=-1);//获取所有的A
                let hasColorAArr=AArr.filter(v=>reCardArr.filter(c=>c%4==v%4).length>=5);//筛选有5张及以上同花含A的A
                let colorGroupArr=[];//同花牌分组
                reCardArr.forEach(v=>{
                    let index=v%4>0?(4-v%4):v%4;//黑桃最前，方片最后
                    !colorGroupArr[index]&&(colorGroupArr[index]=[]);
                    colorGroupArr[index].push(v);
                })
                let pointGroupArr=[];//点数分组
                reCardArr.forEach(v=>{
                    let point=Math.ceil(v / 4);
                    !pointGroupArr[point]&&(pointGroupArr[point]=[]);
                    pointGroupArr[point].push(v);
                })
                if(AArr.length>0&&hasColorAArr.length>0){//有A并且存在有同花的A才有必要判断有没有【皇家同花顺】
                    let arr=[];//存放所有有A对应花色的牌组
                    for (let i = 0; i < hasColorAArr.length; i++) {
                        let hasColorACardArr=reCardArr.filter(v=>v%4==hasColorAArr[i]%4);
                        hasColorACardArr.length>=5&&arr.push(hasColorACardArr.slice(0,5));
                    }
                    array = arr.find(v=>!v.some((c,i)=>v[i+1]&&v[i+1]+4!=c)); //判断5张牌有没有顺子(第一个数除外每个数加4都等于上一个数)
                    if(array&&array.length>0){
                        array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                        return {
                            card:array,
                            type:this.cardType.maxColorOrder
                        }
                    }
                }
                if(colorGroupArr.find(v=>v&&v.length>=5)){//有5张及以上同花才有必要判断有没有【同花顺】
                    let arr=colorGroupArr.filter(v=>v.length>=5);//获取含有5张及以上同花牌分组
                    for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < arr[i].length; j++) {
                            if(arr[i][j-1]){//如果有上一张
                                if(Math.ceil(arr[i][j-1]/4)-Math.ceil(arr[i][j]/4)==1){//并且跟上一个点数相差1则连续，不连续说明可能点数一样或者不连续
                                    array.push(arr[i][j]);
                                    if(array.length==5){//凑够5个就不需要继续了
                                        array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                                        return {
                                            card:array,
                                            type:this.cardType.colorOrder
                                        }
                                    }
                                }
                            }else{//没有上一张
                                if(arr[i].length-j<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                                    array=[];
                                    break;
                                }
                                array=[arr[i][j]];
                            }
                        }
                    }
                }
                let fourArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=4).slice(0,4);//找四张
                if(fourArr.length>0){//根据每张牌点数查找数组中有没有4张同点数的牌【四条】
                    array=fourArr.concat(reCardArr.find(v=>fourArr.indexOf(v)==-1)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.shijo
                    }
                }
                let threeArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=3).slice(0,3);//找三张
                let twoArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2&&threeArr.indexOf(v)==-1).slice(0,2);//找两张
                if(threeArr.length>0&&twoArr.length>0){//有三张一样并且两张一样【葫芦】
                    array=threeArr.concat(twoArr).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.gourd
                    }
                }
                let orderArr=colorGroupArr.find(v=>v&&v.length>=5);//获取含有5张及以上同花牌分组【同花】
                if(orderArr&&orderArr.length>0){
                    array=orderArr.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.color
                    }
                }
                for (let j = 0; j < reCardArr.length; j++) {//【顺子】
                    if(array.length+(reCardArr.length-j)<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                        array=[];
                        break;
                    }
                    if(j==0){
                        array=[reCardArr[j]];
                        continue;
                    }
                    // j==0&&(array=[reCardArr[j]]);//第一张直接放入
                    if(Math.ceil(array[array.length-1]/4)-Math.ceil(reCardArr[j]/4)==1){//如果当前点数与上一张点数相差1则说明两张牌连续
                        array.push(reCardArr[j]);
                        if(array.length==5){//凑够5个就不需要继续了
                            array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                            return {
                                card:array,
                                type:this.cardType.order
                            }
                        }
                    }else if(Math.ceil(reCardArr[j-1]/4)-Math.ceil(reCardArr[j]/4)>1){//如果当前点数与上一张点数大于1则说明两张牌不连续
                        array=[reCardArr[j]];
                    }
                    // if(reCardArr[j-1]){//如果有上一张
                    //     if(Math.ceil(reCardArr[j-1]/4)-Math.ceil(reCardArr[j]/4)==1){//并且跟上一个点数相差1则连续，不连续说明可能点数一样或者不连续
                    //         array.push(reCardArr[j]);
                    //         if(array.length==5){//凑够5个就不需要继续了
                    //             array=array.map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    //             return {
                    //                 card:array,
                    //                 type:this.cardType.order
                    //             }
                    //         }
                    //     }
                    // }else{//没有上一张
                    //     if(reCardArr.length-j<5){//如果剩余的数不足4个就不需要判断了，因为肯定没有顺子
                    //         array=[];
                    //         break;
                    //     }
                    //     array=[reCardArr[j]];
                    // }
                }
                threeArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=3).slice(0,3);//找三张
                if(threeArr.length>0){//三条
                    array=threeArr.concat(reCardArr.filter(v=>threeArr.indexOf(v)==-1).slice(0,2)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.sanjo
                    }
                }
                let twoFirstArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2).slice(0,2);//找两张
                let twoSecondArr= reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2&&twoFirstArr.indexOf(v)==-1).slice(0,2);//找两张
                if(twoFirstArr.length>0&&twoSecondArr.length>0){//两对
                    array=twoFirstArr.concat(twoSecondArr,reCardArr.find(v=>twoFirstArr.indexOf(v)==-1&&twoSecondArr.indexOf(v)==-1)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.twoPairs
                    }
                }
                twoArr=reCardArr.filter(v=>reCardArr.filter(c=>Math.ceil(c/4)==Math.ceil(v/4)).length>=2).slice(0,2);//找两张
                if(twoArr.length>0){//一对
                    array=twoArr.concat(reCardArr.filter(v=>twoArr.indexOf(v)==-1).slice(0,3)).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                    return {
                        card:array,
                        type:this.cardType.pairs
                    }
                }
                //散牌
                array=reCardArr.slice(0,5).map(v=>v==53?1:v==54?2:v==55?3:v==56?4:v);
                if(array.length>=2){
                    return {
                        card:array,
                        type:this.cardType.messy
                    }
                }
            }
            return {
                card:"",
                type:""
            }
        },
        /**
         * 设置最小追加额度
         */
        setMinAppend(seat){
            let lastTwoPlayerArr=[];//上两个有效玩家对象
            for (let i = -1,arr=Object.values(this.playerObj); i > -8; i--) {
                let p=arr[(seat+i+7)%7];
                //玩家无效、弃牌、空闲、准备、金额与上一个玩家一致(即跟注的)、金额小于上一个玩家并且状态为allin()则跳过
                if(
                    JSON.stringify(p)=="{}"||p.state==this.playerStateType.wait.id||p.state==this.playerStateType.ready.id||p.state==this.playerStateType.giveup.id||
                    (lastTwoPlayerArr.length==1&&p.currbet==lastTwoPlayerArr[0].currbet)||
                    (lastTwoPlayerArr.length==1&&p.currbet<lastTwoPlayerArr[0].currbet&&p.state==this.playerStateType.allin.id)
                ){
                    continue;
                }
                lastTwoPlayerArr.push(p);
                if(lastTwoPlayerArr.length==2){
                    break;
                }
            }
            if(lastTwoPlayerArr.length==2){
                this.minAppend=Math.abs(lastTwoPlayerArr[0].currbet-lastTwoPlayerArr[1].currbet)+Math.max(lastTwoPlayerArr[0].currbet,lastTwoPlayerArr[1].currbet);//找到当前玩家的上两家本轮额度之差为最小追加额度
            }else{
                this.minAppend=1;
            }
            this.rangeAppend=this.minAppend;
        },
        /**
         * 发公牌
         */
        setPubcard(index){
            return new Promise(async (resolve)=>{
                let pubCardAnimWorker=new Worker("timeWorker.js");
                pubCardAnimWorker.onmessage=(e)=>{
                    if(e.data==0){
                        this.pubCardClassArr[index]="give-pub-poker"+(index+1);
                        resolve();
                    }
                }
                pubCardAnimWorker.postMessage({number:1,ms:200});//每张耗时200ms
            })
        },
        /**
         * 累计池
         */
        sumPool(){
            let poolArr=JSON.parse(JSON.stringify(this.poolArr));
            let playerArr=Object.values(this.playerObj);
            if(playerArr.some(v=>v.currbet>0)){//存在有效玩家本轮额度大于0
                //以额度最少玩家的额度（大于0）为准，第一次将相同部分放入最后一个池（没有池则开新池），剩余继续以额度最少玩家的额度（大于0）为准，将相同部分开新池放入
                let currbetArr = playerArr
                    .filter(p=>p.currbet>0&&p.state!=this.playerStateType.wait.id&&p.state!=this.playerStateType.ready.id&&p.state!=this.playerStateType.giveup.id)
                    .map(v=>v.currbet);//获取所有有效且本轮额度大于0的玩家的额度
                let arr=Array.from(new Set(currbetArr));//数组去重
                let count=arr.length;
                for (let i = 0; i < count; i++) {
                    let min=Math.min.apply(null,arr);//获取最小额度
                    if(i==0&&poolArr.length!=0){//本次额度第一次入池并且已有池
                        poolArr[poolArr.length-1]+=(min*currbetArr.length);//加入最后一个池
                    }else{
                        //开新池
                        poolArr.push(min*currbetArr.length);
                    }
                    arr=arr.filter(v=>v!=min).map(v=>v-min);
                    currbetArr=currbetArr.filter(v=>v!=min).map(v=>v-min);
                }
            }
            return poolArr;
        },
        /**
         * 设置池
         */
        setPool(poolArr){
            return new Promise(async (res)=>{
                for (let i = 0; i < poolArr.length; i++) {
                    if(poolArr[i]!=this.poolArr[i]){//池有变动
                        let offset=poolArr[i]-(this.poolArr[i]?this.poolArr[i]:0);
                        await new Promise((resolve)=>{
                            let poolNumWorker=new Worker("timeWorker.js");
                            let count=offset<10?1:10;//根据额度设置计数的次数
                            poolNumWorker.onmessage=(e)=>{
                                this.poolArr[i]=(this.poolArr[i]?this.poolArr[i]:0)+(offset/count);
                                (e.data==0)&&resolve();
                            }
                            poolNumWorker.postMessage({number:count,ms:500/count});
                        })
                    }
                    // i==poolArr.length-1&&
                }
                res();
            })
        },
        /**
         * 打扫战场
         */
        cleanRoom(){
            if(this.gameInfo.state==this.gameStateType.wait.id){//只有在游戏状态为空闲才能打扫战场
                this.isShowScore=false;//隐藏结算
                this.gameInfo.pubcard=[];//清除公牌
                this.playerCardArr=[];//清除所有玩家私牌
                //清除所有身份
                this.gameInfo.dseat=0;
                this.gameInfo.sseat=0;
                this.gameInfo.bseat=0;
                this.roleClassArr=[];
                this.gameInfo.antes=0;//清除基础额度
                this.gameInfo.countdown=0;//清除倒计时
                Object.values(this.playerObj).forEach(v=>JSON.stringify(v)!="{}"&&(v.currbet=0)&&(v.boutbet=0));//清除玩家当前下注额
                this.tableCardClassArr=[];//清除玩家桌牌class
                this.handCardClassArr=[];//清除玩家手牌class
                this.chipClassArr=[];//清除额度class
                this.countdownStyleArr=[];//清除玩家倒计时样式
                this.pubCardClassArr=[];//清除公牌class
                this.playerScoreArr=[];//清除玩家成绩
            }
        },
        /**
         * 获取当前剩余带入金
         */
        getCurrCarry(){
            let player=this.getPlayerOfMy(0);
            if(player){
                return this.roomInfo.carry-player.boutbet-player.currbet;
            }
            return 0;
        }
    },
    watch: {
        /**
         * 监听guid改变（断线重连）
         */
        "userInfo.guid"(newVal,oldVal){
            if(newVal!=oldVal&&newVal!=""){
                this.init();
            }
        },
    },
};
</script>